<template>
  <div>
    <div v-for="(suggestion, index) in suggestions" :key="index"
      :class="getRowClass(suggestion, index)">
      <div class="row mx-0">
        <div v-if="isFoundBalances || !isSuggestionInFoundBalances(suggestion)" class="col-12 py-3 border mx-0">
          <div class="row">

            <div class="col-12" v-if="suggestion.orderItem && suggestion.orderItem.item">
              <div class="row">

                <div class="col-auto img-fix-size-suggestions px-sm-2 cursor-pointer"
                  v-if="suggestion.orderItem.item.images.length"
                  @click.prevent="openImagemModal(suggestion.orderItem.item.images)">
                  <img :src="suggestion.orderItem.item.images[0].url" width="100%">
                </div>

                <div class="col">
                  <div class="row">

                    <div class="col-12 pb-xl-0">
                      <span class="font-weight-bold">Item: </span>
                      {{getItemReduzidoFromRef(suggestion.orderItem.item.ref) + ' - ' + suggestion.orderItem.item.description}}
                    </div>
                    <div v-if="suggestion.orderItem.item.sku" class="col-12">
                      <span class="font-weight-bold">Referência: </span>
                      {{suggestion.orderItem.item.sku }}
                    </div>
                    <small v-if="suggestion.orderItem.item.gtin" class="col-12 text-muted">
                      <span class="font-weight-bold">Cód. barras: </span>
                      {{suggestion.orderItem.item.gtin }}
                    </small>

                  </div>
                </div>

              </div>
            </div>

            <div class="col-12">
              <div class="row mx-0 align-items-xl-start align-items-end">
                <div class="col col-sm px-0 px-sm pl-lg-0">
                  <div class="row mx-0">

                    <div class="col pt-sm-0">
                      <div class="row">

                        <div class="col-lg-12 col-sm-6 col-12 pl-0">
                          <span class="font-weight-bold">Endereço: </span>
                          <h5 class="font-weight-bold">{{ suggestion.location ? suggestion.location.name : '' }}</h5>
                        </div>
                        <div v-if="(selectItem && selectItem.item && !selectItem.item.automaticItemLot) || (!selectItem && (!suggestion.orderItem || !suggestion.orderItem.item || !suggestion.orderItem.item.automaticItemLot))"
                          class="col-lg-12 col-sm-6 col-12 pl-0">
                          <span class="font-weight-bold">Número do Lote: </span>
                          <h5 class="font-weight-bold">
                            <text-highlight :queries="(isFoundBalances || (foundBalances && foundBalances.length)) ? suggestionsLotPrefixes : []">
                              {{ suggestion.itemLot ? suggestion.itemLot.lotNumber : '' }}
                            </text-highlight>
                          </h5>
                        </div>

                      </div>
                    </div>

                    <div class="col-xl-4 col-md-12">
                      <div class="row">

                        <div class="col-lg-12 col-sm-6 col-12 px-0">
                          <span class="font-weight-bold">
                            Data <span v-if="(selectItem && selectItem.item && !selectItem.item.automaticItemLot) || (suggestion.orderItem && suggestion.orderItem.item && !suggestion.orderItem.item.automaticItemLot)"> do Lote</span>:
                          </span>
                          {{suggestion.formattedDate}}
                        </div>
                        <div class="col-lg-12 col-sm-6 col-12 px-0">
                          <span class="font-weight-bold">Quantidade Atual: </span>
                          <composite-item-lot-quantity
                            :quantity="suggestion.currentQuantity"
                            :itemLot="suggestion.itemLot"
                          ></composite-item-lot-quantity>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>

                <div class="col-12 col-lg px-0 px-sm pt-2 pt-sm-3 pt-xl-4 pl-xl-5">
                  <div class="row align-items-center">
                    <div v-if="!isFoundBalances && !savingMovement" class="col-xl-3 col-lg-12 col-md-auto col-12 mb-2">
                      <b-form-checkbox v-model="suggestion.automatic">
                        <span class="font-weight-bold">Automático</span>
                      </b-form-checkbox>
                    </div>
                    <div v-if="!savingMovement" class="col">
                      <div class="row align-items-center">
                        <div v-if="suggestion && suggestion.itemLot && suggestion.itemLot.quantityPerVolume" class="col">
                          <b-input v-model="suggestion.numberOfVolumes" :id="suggestion.id" @focus="updateEditingSuggestionQuantity(true)"
                          onFocus="this.setSelectionRange(0, this.value.length)" @blur="setSuggestionNumberOfVolumes(index)"
                          @keyup.enter="checkClicked(suggestion)">
                          </b-input>
                        </div>
                        <div v-if="suggestion && suggestion.itemLot && suggestion.itemLot.quantityPerVolume" class="col-auto px-0">
                          <span class="text-muted">x </span> {{ suggestion.itemLot.quantityPerVolume }} =
                        </div>
                        <div class="col">
                          <b-input v-model="suggestion.quantity" :id="suggestion.id" @focus="updateEditingSuggestionQuantity(true)"
                          onFocus="this.setSelectionRange(0, this.value.length)" :ref="isFoundBalances ? 'foundBalancesQuantityField' : ''"
                          @blur="isFoundBalances ? updateEditingSuggestionQuantity(false) : setSuggestionManualQuantity(index)"
                          @keyup.enter="checkClicked(suggestion)"></b-input>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto">
                      <div v-if="savingMovement" class="col">
                        <loading-animation />
                      </div>
                      <b-button class="float-md-right" v-if="!savingMovement && suggestion.quantity && suggestion.quantity <= suggestion.currentQuantity" variant="primary"
                        @click.prevent="checkClicked(suggestion)"
                        :disabled="editingSuggestionQuantity">
                        <i class="fa fa-check"></i>
                      </b-button>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>

      </div>
    </div>

    <image-modal
      ref="imageModal"
      title="Imagens do item"
      :images="itemImages"
    ></image-modal>
  </div>
</template>

<script>
  import { LoadingAnimation } from '@/components/loaders';
  import shared from '@/shared/shared';
  import TextHighlight from 'vue-text-highlight';
  import CompositeItemLotQuantity from '@/components/common/composite-item-lot-quantity';
  import ImageModal from '@/components/common/image-modal';

  export default {
    name: 'prepare-suggestions-table',
    components: {
      TextHighlight,
      CompositeItemLotQuantity,
      LoadingAnimation,
      ImageModal
    },

    data () {
      return {
        itemImages: []
      }
    },

    props: {
      isFoundBalances: {
        type: Boolean
      },
      suggestions: {
        type: Array
      },
      selectedSuggestion: {
        type: Object
      },
      foundBalances: {
        type: Array
      },
      suggestionsLotPrefixes: {
        type: Array
      },
      savingMovement: {
        type: Boolean
      },
      editingSuggestionQuantity: {
        type: Boolean
      },
      setSuggestionManualQuantity: {
        type: Function,
        default () {
          return;
        }
      },
      openLotConfirmationModal: {
        type: Function,
        default () {
          return;
        }
      },
      openLocationConfirmationModal: {
        type: Function,
        default () {
          return;
        }
      },
      updateEditingSuggestionQuantity: {
        type: Function,
        default () {
          return;
        }
      },
      selectItem: {
        type: Object
      },
      updateSuggestions: {
        type: Function
      },
      reloadSuggestions: {
        type: Function
      }
    },

    methods: {
      getRowClass(suggestion, index) {
        if (this.isFoundBalances) {
          return {
            'bg-light': index % 2 != 0 && (!this.selectedSuggestion || this.selectedSuggestion.id !== suggestion.id) && !suggestion.doesNotBelongToSuggestions,
            'bg-info': this.selectedSuggestion && this.selectedSuggestion.id === suggestion.id,
            'bg-danger': suggestion.doesNotBelongToSuggestions
          };
        } else {
          return { 'bg-light': index % 2 != 0 };
        }
      },

      isSuggestionInFoundBalances(suggestion) {
        return this.foundBalances && this.foundBalances.find(b => b.id === suggestion.id);
      },

      getItemReduzidoFromRef(ref) {
        return shared.getItemReduzidoFromRef(ref);
      },

      focusOnFoundBalancesQuantityField() {
        let _this = this;
        setTimeout(() => {
          if (_this.foundBalances.length === 1 && _this.$refs.foundBalancesQuantityField && _this.$refs.foundBalancesQuantityField.length) {
            _this.$refs.foundBalancesQuantityField[0].$el.focus();
          }
        }, 2);
      },

      setSuggestionNumberOfVolumes(index) {
        if (this.suggestions[index].itemLot && this.suggestions[index].itemLot.quantityPerVolume &&
          this.suggestions[index].numberOfVolumes) {
          this.suggestions[index].quantity = this.suggestions[index].itemLot.quantityPerVolume * this.suggestions[index].numberOfVolumes;
          this.updateSuggestions(this.suggestions, this.isFoundBalances);

          this.$nextTick(() => {
            if (!this.isFoundBalances) {
              this.setSuggestionManualQuantity(index);
            }
          });
        }
      },

      openImagemModal(images) {
        if (images.length) {
          this.itemImages = images;
          this.$refs.imageModal.showModal();
        }
      },

      checkClicked(suggestion) {
        if (!this.isFoundBalances || (suggestion && suggestion.itemLot && suggestion.itemLot.item && suggestion.itemLot.item.automaticItemLot)) {
          this.openLotConfirmationModal(suggestion, this.isFoundBalances);
        } else {
          this.openLocationConfirmationModal(suggestion, this.isFoundBalances);
        }
      }
    }
  }
</script>

<style scoped>
</style>
