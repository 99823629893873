<template>
  <b-modal
    size="xl"
    centered
    lazy
    :title="title"
    header-bg-variant="info"
    footer-border-variant="info"
    @hidden="onHidden"
    v-model="show"
    :hide-footer="true"
    :id="id"
  >
    <b-container>
      <div class="card mb-0 mx-n3">
        <div class="card-body py-2 p-sm-3 px-0">
          <div class="row mx-md-n3 mx-0" :class="{
              'align-items-start': !selectItem || !selectItem.item || !selectItem.item.automaticItemLot,
              'align-items-end': selectItem && selectItem.item && selectItem.item.automaticItemLot,
            }">
            <div v-if="!selectItem || !selectItem.item || !selectItem.item.automaticItemLot" class="col-xl-3 col-sm-6 col-12">
              <div v-if="loadingSuggestionLotBarcode" class="mb-2 mx-5">
                <loading-animation />
              </div>
              <div v-else>
                <b-form-group
                  :label="'Cód. de barras do Lote' + (selectItem ? '' : '/Item')"
                  label-for="selectSuggestionByBarcodeItemLotBarcodeInput"
                  class="mb-0"
                >
                  <b-button
                    v-if="useCamera"
                    variant="secondary"
                    @click.prevent="openHeaderItemLotBarcodeInputModal()"
                  >
                    <i class="fa fa-barcode"></i>
                  </b-button>
                  <b-form-input
                    v-else
                    id="selectSuggestionByBarcodeItemLotBarcodeInput"
                    v-model="innerSelectSuggestionByBarcodeItemLotBarcode"
                    @keyup.enter="headerItemLotInputBeeped()"
                    ref="selectSuggestionByBarcodeItemLotBarcodeInput"
                  />
                </b-form-group>
              </div>

            </div>
            <div class="col-xl-3 col-sm-6 col-12 mt-sm-0 mt-3">
              <div v-if="loadingSuggestionLocationBarcode" class="mb-2 mx-5">
                <loading-animation />
              </div>
              <b-form-group v-else label="Cód. de barras do Endereço" label-for="selectSuggestionByBarcodeLocationBarcodeInput" class="mb-0">
                <b-button
                  v-if="useCamera"
                  variant="secondary"
                  :disabled="headerLocationBarcodeInputDisabled"
                  @click.prevent="openHeaderLocationBarcodeInputModal()"
                >
                  <i class="fa fa-barcode"></i>
                </b-button>
                <b-form-input v-else id="selectSuggestionByBarcodeLocationBarcodeInput" v-model="innerSelectSuggestionByBarcodeLocationBarcode"
                  @keyup.enter="headerLocationInputBeeped()"
                  :disabled="headerLocationBarcodeInputDisabled"
                  ref="selectSuggestionByBarcodeLocationBarcodeInput"/>
              </b-form-group>
              <b-form-checkbox v-if="previousLocation && (!selectItem || (selectItem.item && !selectItem.item.automaticItemLot))"
                :disabled="Boolean(foundBalances && foundBalances.length) || Boolean(order && order.sourceLocation)" v-model="innerUsePreviousLocation" class="pt-1">
                Usar endereço anterior
              </b-form-checkbox>
            </div>
            <div class="col-xl-6 col-12 mt-xl-0 mt-3">
              <div class="row align-items-end">
                <div v-if="showHeaderQuantityInputs" class="col-sm-8 col-12">
                  <b-form-group label="Quantidade"  class="mb-0">
                    <div class="row align-items-center">
                      <div class="col" v-if="foundBalances && foundBalances.length
                      && foundBalances[0].itemLot && foundBalances[0].itemLot.quantityPerVolume">
                        <b-form-input type="text" :disabled="!usePreviousLocation
                        && (!foundBalances || !foundBalances.length)" v-model="innerNumberOfVolumes"/>
                      </div>
                      <div v-if="foundBalances && foundBalances.length && foundBalances[0].itemLot
                      && foundBalances[0].itemLot.quantityPerVolume" class="col-auto px-0">
                        <span class="text-muted">x </span> {{ foundBalances[0].itemLot.quantityPerVolume }} =
                      </div>
                      <div class="col">
                        <b-form-input type="text" :disabled="!usePreviousLocation
                        && (!foundBalances || !foundBalances.length)" v-model="innerSuggestionInitialQuantity"/>
                      </div>
                    </div>
                  </b-form-group>
                </div>
                <div class="col-sm-4 col-12 pt-3">
                  <b-button variant="secondary" @click.prevent="getSuggestions" :disabled="loadingSuggestions" class="w-100">
                    Recarregar
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h6 class="font-weight-bold mt-4">Sugestões encontradas:</h6>

      <div v-if="foundBalances && foundBalances.length" class="mx-n3">
        <prepare-suggestions-table
          ref="foundBalancesPrepareSuggestionsTable"
          :isFoundBalances="true"
          :suggestions="foundBalances"
          :selectedSuggestion="selectedSuggestion"
          :foundBalances="foundBalances"
          :suggestionsLotPrefixes="suggestionsLotPrefixes"
          :savingMovement="savingMovement"
          :editingSuggestionQuantity="editingSuggestionQuantity"
          :selectItem="selectItem"
          :updateSuggestions="innerUpdateSuggestions"
          :openLotConfirmationModal="openLotConfirmationModal"
          :openLocationConfirmationModal="openLocationConfirmationModal"
        ></prepare-suggestions-table>
      </div>

      <h6 class="font-weight-bold mt-4">Sugestões de movimento:</h6>

      <div v-if="loadingSuggestions" class="mb-5 pb-5">
        <loading-animation />
      </div>

      <div v-if="!loadingSuggestions && (!suggestions || !suggestions.length)" class="mb-3">
        <div class="row bg-light py-3">
          <div class="col text-center">
            Nenhuma sugestão encontrada
          </div>
        </div>
      </div>

      <div v-if="!loadingSuggestions && suggestions && suggestions.length" class="mb-3 mx-n3">
        <prepare-suggestions-table
          :isFoundBalances="false"
          :suggestions="suggestions"
          :selectedSuggestion="selectedSuggestion"
          :foundBalances="foundBalances"
          :suggestionsLotPrefixes="suggestionsLotPrefixes"
          :savingMovement="savingMovement"
          :editingSuggestionQuantity="editingSuggestionQuantity"
          :setSuggestionManualQuantity="setSuggestionManualQuantity"
          :openLotConfirmationModal="openLotConfirmationModal"
          :openLocationConfirmationModal="openLocationConfirmationModal"
          :selectItem="selectItem"
          :updateSuggestions="innerUpdateSuggestions"
          :reloadSuggestions="reloadSuggestions"
        ></prepare-suggestions-table>
      </div>

      <h6 class="font-weight-bold mt-4">Movimentos lançados:</h6>

      <finished-movements-table
        :loadingItemLotMovements="loadingItemLotMovements"
        :itemLotMovements="itemLotMovements"
        :foundBalances="foundBalances"
        :isItemLotMovementsLastPage="isItemLotMovementsLastPage"
        :loadNextItemLotMovements="loadNextItemLotMovements"
        :suggestionsLotPrefixes="suggestionsLotPrefixes"
      ></finished-movements-table>

      <div v-if="selectItem" class="row">
        <div class="col-sm-auto col-12 px-0">
          <b-button variant="secondary" @click.prevent="onShow(selectItem)" class="mobile-btn-100">
            Selecionar Manualmente
          </b-button>
        </div>
      </div>
    </b-container>

    <barcode-input-modal
      id="header-item-lot-barcode-input-modal"
      :loading="loadingSuggestionLotBarcode"
      :ok="headerItemLotInputBeeped"
      :config="config"
      :authUser="authUser"
      :parameter="parameter"
    ></barcode-input-modal>

    <barcode-input-modal
      id="header-location-barcode-input-modal"
      :loading="loadingSuggestionLocationBarcode"
      :ok="headerLocationInputBeeped"
      :config="config"
      :authUser="authUser"
      :parameter="parameter"
    ></barcode-input-modal>
  </b-modal>
</template>

<script>
  import { LoadingAnimation } from '@/components/loaders';
  import TextHighlight from 'vue-text-highlight';
  import PrepareSugggestionsTable from '@/components/tables/prepare-suggestions-table';
  import CompositeItemLotQuantity from '@/components/common/composite-item-lot-quantity';
  import BarcodeInputModal from "@/components/common/barcode-input-modal";
  import FinishedMovementsTable from '@/components/tables/finished-movements-table'
  import shared from '@/shared/shared';

  export default {
    name: 'prepare-suggestions-modal',
    components: {
      LoadingAnimation,
      TextHighlight,
      'prepare-suggestions-table': PrepareSugggestionsTable,
      CompositeItemLotQuantity,
      BarcodeInputModal,
      FinishedMovementsTable
    },

    props: {
      title: {
        type: String,
        require
      },
      id: {
        type: String,
        require
      },
      onHidden: {
        type: Function,
        default () {
          return;
        }
      },
      selectItem: {
        type: Object
      },
      loadingSuggestionLotBarcode: {
        type: Boolean
      },
      selectSuggestionByBarcodeItemLotBarcode: {
        type: String
      },
      readSuggestionLotBarcode: {
        type: Function,
        default () {
          return;
        }
      },
      loadingSuggestionLocationBarcode: {
        type: Boolean
      },
      selectSuggestionByBarcodeLocationBarcode: {
        type: String
      },
      readSuggestionLocationBarcode: {
        type: Function,
        default () {
          return;
        }
      },
      foundBalances: {
        type: Array
      },
      usePreviousLocation: {
        type: Boolean
      },
      previousLocation: {
        type: Object
      },
      suggestionInitialQuantity: {},
      loadingSuggestions: {
        type: Boolean
      },
      getSuggestions: {
        type: Function,
        default () {
          return;
        }
      },
      selectedSuggestion: {
        type: Object
      },
      suggestionsLotPrefixes: {
        type: Array
      },
      savingMovement: {
        type: Boolean
      },
      editingSuggestionQuantity: {
        type: Boolean
      },
      suggestions: {
        type: Array
      },
      setSuggestionManualQuantity: {
        type: Function,
        default () {
          return;
        }
      },
      reloadSuggestions: {
        type: Function
      },
      openLotConfirmationModal: {
        type: Function,
        default () {
          return;
        }
      },
      openLocationConfirmationModal: {
        type: Function,
        default () {
          return;
        }
      },
      loadingItemLotMovements: {
        type: Boolean
      },
      itemLotMovements: {
        type: Array
      },
      loadNextItemLotMovements: {
        type: Function,
        default () {
          return;
        }
      },
      isItemLotMovementsLastPage: {
        type: Boolean
      },
      onShow: {
        type: Function,
        default () {
          return;
        }
      },
      updateEditingSuggestionQuantity: {
        type: Function,
        default () {
          return;
        }
      },
      updateSelectSuggestionByBarcodeItemLotBarcode: {
        type: Function,
        default () {
          return;
        }
      },
      updateSelectSuggestionByBarcodeLocationBarcode: {
        type: Function,
        default () {
          return;
        }
      },
      updateSuggestionInitialQuantity: {
        type: Function,
        default () {
          return;
        }
      },
      updateUsePreviousLocation: {
        type: Function,
        default () {
          return;
        }
      },
      updateSuggestions: {
        type: Function
      },
      numberOfVolumes: {},
      updateManualSelectionNumberOfVolumes: {
        type: Function,
        default () {
          return;
        }
      },
      config: {
        type: Object
      },
      authUser: {
        type: Object
      },
      parameter: {
        type: Object
      },
      order: {
        type: Object
      }
    },

    data() {
      return {
        show: false
      }
    },

    computed: {
      innerSelectSuggestionByBarcodeItemLotBarcode: {
        get() {
          return this.selectSuggestionByBarcodeItemLotBarcode;
        },
        set(value) {
          this.updateSelectSuggestionByBarcodeItemLotBarcode(value);
        }
      },
      innerSelectSuggestionByBarcodeLocationBarcode: {
        get() {
          return this.selectSuggestionByBarcodeLocationBarcode;
        },
        set(value) {
          this.updateSelectSuggestionByBarcodeLocationBarcode(value);
        }
      },
      innerSuggestionInitialQuantity: {
        get() {
          return this.suggestionInitialQuantity;
        },
        set(value) {
          this.updateSuggestionInitialQuantity(value);
        }
      },
      innerNumberOfVolumes: {
        get() {
          return this.numberOfVolumes;
        },
        set(value) {
          this.updateManualSelectionNumberOfVolumes(value);
        }
      },
      innerUsePreviousLocation: {
        get() {
          return this.usePreviousLocation;
        },
        set(value) {
          this.updateUsePreviousLocation(value);
        }
      },
      showHeaderQuantityInputs() {
        return !this.selectItem || !this.selectItem.item || !this.selectItem.item.automaticItemLot;
      },
      useCamera() {
        return shared.useCamera(this.parameter, this.config);
      },
      autoOpenCamera() {
        return shared.autoOpenCamera(this.parameter, this.config);
      },
      headerLocationBarcodeInputDisabled() {
        return Boolean(
          (this.order && this.order.sourceLocation) || (
            (!this.selectItem || !this.selectItem.item || !this.selectItem.item.automaticItemLot) && (!this.foundBalances || !this.foundBalances.length || this.usePreviousLocation)
          )
        );
      }
    },

    methods: {
      showModal(){
        this.show = true;
      },

      hideModal() {
        this.show = false;
      },

      focusOnSuggestionItemLotBarcodeInput() {
        if (this.useCamera && this.autoOpenCamera) {
          this.openHeaderItemLotBarcodeInputModal();
        } else if (this.$refs.selectSuggestionByBarcodeItemLotBarcodeInput) {
          this.$refs.selectSuggestionByBarcodeItemLotBarcodeInput.$el.focus();
        }
      },

      focusOnSuggestionLocationBarcodeInput() {
        if (this.useCamera && this.autoOpenCamera) {
          this.openHeaderLocationBarcodeInputModal();
        } else if (this.$refs.selectSuggestionByBarcodeLocationBarcodeInput) {
          this.$refs.selectSuggestionByBarcodeLocationBarcodeInput.$el.focus();
        }
      },

      focusOnFoundBalancesQuantityField() {
        if (this.$refs.foundBalancesPrepareSuggestionsTable) {
          this.$refs.foundBalancesPrepareSuggestionsTable.focusOnFoundBalancesQuantityField();
        }
      },

      innerUpdateSuggestions(suggestions, isFoundBalances) {
        this.updateSuggestions(suggestions, isFoundBalances, this.selectItem);
      },

      clickOnCheck(suggestion) {
        if (this.$refs.foundBalancesPrepareSuggestionsTable) {
          this.$refs.foundBalancesPrepareSuggestionsTable.checkClicked(suggestion);
        }
      },

      getHeaderInputValues() {
        if (this.showHeaderQuantityInputs) {
          return {
            quantity: this.innerSuggestionInitialQuantity,
            numberOfVolumes: this.innerNumberOfVolumes
          };
        } else {
          return null;
        }
      },

      openHeaderItemLotBarcodeInputModal() {
        this.$bvModal.show("header-item-lot-barcode-input-modal");
      },

      headerItemLotInputBeeped(barcode) {
        if (barcode !== undefined) {
          this.innerSelectSuggestionByBarcodeItemLotBarcode = barcode;
          this.$bvModal.hide("header-item-lot-barcode-input-modal");
        }

        this.readSuggestionLotBarcode(this.getHeaderInputValues());
      },

      openHeaderLocationBarcodeInputModal() {
        this.$bvModal.show("header-location-barcode-input-modal");
      },

      headerLocationInputBeeped(barcode) {
        if (barcode !== undefined) {
          this.innerSelectSuggestionByBarcodeLocationBarcode = barcode;
          this.$bvModal.hide("header-location-barcode-input-modal");
        }

        this.readSuggestionLocationBarcode(this.getHeaderInputValues());
      }
    }
  }
</script>

<style scoped>
</style>
