<template>
  <div class="animated fadeIn">
    <div v-if="loadingOrder" class="row justify-content-center">
      <div class="col mt-3 mb-3">
        <loading-animation />
      </div>
    </div>

    <div v-else-if="!order" class="text-center">
      <p>Pedido não encontrado</p>
    </div>

    <div v-else-if="!order.stockLocale" class="text-center">
      <p>Esse pedido não possui armazém</p>
    </div>

    <div v-else-if="!order.orderItems || !order.orderItems.length" class="text-center">
      <p>Esse pedido não possui itens</p>
    </div>

    <div v-else>
      <div class="row justify-content-between mb-3">
        <div class="col-auto">
          <h4 class="ml-md-0 ml-3">Documento {{order.number}}</h4>
        </div>
        <div v-if="!isComplete" class="col-md col-12">
          <div class="row justify-content-md-end justify-content-sm-between justify-content-end">
            <div class="col-auto pr-sm-0 pr-3">
              <b-button variant="primary" @click="openWholeOrderSuggestionsModal" class="mr-3 ml-md-0 ml-3 btn-lg"
                :disabled="!order.orderItems || !order.orderItems.length">
                Buscar sugestões de todos os itens
              </b-button>
            </div>
            <div v-if="order && (order.productionMS || (order.transformationMS && order.transformationMS.system === 'C'))" class="col-auto">
              <b-button variant="warning" @click="openOrderFinishModal" class="mr-md-0 mr-3 ml-sm-0 ml-3 mt-lg-0 mt-3"
                :disabled="!order.orderItems || !order.orderItems.length">
                Finalizar pedido
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <b-row class="mx-md-n3 mx-1">
        <b-col v-if="isComplete">
          <b-col>
            <h1>Separação finalizada!!</h1>
            <p>Você sera redirecionado em alguns segundos... </p>
          </b-col>
        </b-col>

        <div v-else-if="savingOrder" class="mb-5 pb-5">
          <loading-animation />
        </div>

        <div v-else class="col-12">
          <div class="row">
            <div
              class="col-12 col-md-6 col-xl-4 px-2"
              v-bind:class="{
                'col-sm-6': !orderItem.item.images.length,
                'col-sm-12': orderItem.item.images.length
              }"
              @click.prevent="showSuggestions(orderItem)"
              @contextmenu="openOrderItemContextMenu($event, orderItem, index)"
              ref='box'
              v-for="(orderItem, index) in order.orderItems"
              :key="orderItem.id"
              :id="orderItem.id"
            >
              <div class="card pointer"
                      :ref="orderItem.id"
                      :class="orderItem.alocatedQuantity >= orderItem.quantity ? 'text-white  bg-success' : ''">
                <div class="card-body mx-md-0 mx-3 px-2">
                  <div class="row mx-0">
                    <div class="col pl-0 pr-2 img-fix-size" v-if="orderItem && orderItem.item && orderItem.item.images.length">
                      <img :src="orderItem.item.images[0].url" width="100%">
                    </div>
                    <div class="col px-0">
                      <div v-if="orderItem.alocatedQuantity >= orderItem.quantity" class="h1 text-muted text-right mb-4">
                        <i class="cui-check"></i>
                      </div>
                      <div class="h5 mb-0">{{ orderItem.item.description }}</div>
                        <p class="text-muted mb-1">{{ getItemReduzidoFromRef(orderItem.item.ref) }}</p>
                        <small v-if="orderItem.alocatedQuantity < orderItem.quantity" class="text-muted text-uppercase font-weight-bold">
                          Pendentes: {{ orderItem.quantity - orderItem.alocatedQuantity }}
                        </small>
                      </div>
                    </div>
                    <b-progress
                          v-if="orderItem.alocatedQuantity < orderItem.quantity"
                          height={}
                          class="progress-xs mt-3 mb-0"
                          variant="info"
                          :value="(orderItem.alocatedQuantity / orderItem.quantity) * 100"/>
                  </div>
              </div>
            </div>
            <div v-if="order && order.transformationMS && order.transformationMS.system === 'C'"
              @click="openOrderItemEditModal(null)"
              class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
              <div class="card pointer">
                <div class="card-body text-center py-5 text-muted">
                  <i class="fa fa-plus fa-4x my-1"></i><br>
                  <h5 class="font-weight-bold">Adicionar item</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <b-modal
          size="xl"
          centered
          lazy
          :title="modalTitle"
          header-bg-variant="info"
          footer-border-variant="info"
          v-model="modalShow"
          :hide-footer="true"
          @hidden="onSecondLayerHidden"
          id="movements-modal"
        >
          <b-container class="pl-sm-3 pl-0">
            <div class="row">
              <div class="col-lg-6 col-12">
                <div class="row">
                  <div class="col-10">
                    <model-list-select
                      v-if="this.selectItem.locals"
                      :list="this.selectItem.locals"
                      option-value="id"
                      option-text="name"
                      v-model="selectedLocal"
                      placeholder="Selecione o Endereço"
                      @searchchange="searchAllocation"
                      :filterPredicate="alwaysTrueFilterPredicate"
                      :isDisabled="Boolean(order && order.sourceLocation)"
                    />
                  </div>
                  <div v-if="loadingAllocations" class="col-2 mb-lg-0 mb-3">
                    <loading-animation />
                  </div>
                  <div v-else class="col-2 mb-lg-0 mb-3">
                    <b-button
                      variant="secondary"
                      @click.prevent="openLocationBarcodeInput"
                      :disabled="Boolean(order && order.sourceLocation)"
                    >
                      <i class="fa fa-barcode"></i>
                    </b-button>
                  </div>

                  <div v-if="!isSelectItemItemLotAutomatic" class="col-10 mt-lg-3 mt-0">
                    <model-list-select
                      v-if="this.selectItem.lotsAvailable"
                      :list="this.selectItem.lotsAvailable"
                      option-value="id"
                      option-text="lotNumber"
                      v-model="selectedLot"
                      placeholder="Selecione o Lote"
                      @searchchange="searchLot"
                      :filterPredicate="alwaysTrueFilterPredicate"/>
                  </div>
                  <div v-if="!isSelectItemItemLotAutomatic && loadingLots" class="col-2 mb-lg-0 mb-3 mt-lg-3 mt-0">
                    <loading-animation />
                  </div>
                  <div v-if="!isSelectItemItemLotAutomatic && !loadingLots" class="col-2 mb-lg-0 mb-3 mt-lg-3 mt-0">
                    <b-button variant="secondary" @click.prevent="openLotBarcodeInput">
                      <i class="fa fa-barcode"></i>
                    </b-button>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-12 mt-lg-0 mt-3">
                <div class="row">
                  <div v-if="savingMovement || loadingBalances" class="col-10">
                    <loading-animation />
                  </div>
                  <div v-if="!savingMovement && !loadingBalances && localsAndLotSelected" class="col-auto col-12 pr-0 pb-2">
                    <label for="quantity" class="mb-0 margin-right">Quantidade a ser removida:</label>
                  </div>
                  <div class="row align-items-center mx-0">
                    <div v-if="!savingMovement && !loadingBalances && localsAndLotSelected && selectedLot.quantityPerVolume" class="col">
                      <b-input v-model="manualSelectionNumberOfVolumes" id="manualSelectionNumberOfVolumes" onFocus="this.setSelectionRange(0, this.value.length)"></b-input>
                    </div>
                    <div v-if="!savingMovement && !loadingBalances && localsAndLotSelected && selectedLot.quantityPerVolume" class="col-auto px-0">
                      <span class="text-muted">x </span>
                      {{ selectedLot.quantityPerVolume }} =
                    </div>
                    <div v-if="!savingMovement && !loadingBalances && localsAndLotSelected" class="col">
                      <b-input v-model="quantity" id="quantity" onFocus="this.setSelectionRange(0, this.value.length)"></b-input>
                    </div>
                    <div v-if="!savingMovement && !loadingBalances && localsAndLotSelected" class="col-2">
                      <b-button class="float-right" variant="primary" @click.prevent="preAddItem" :disabled="quantity <= 0">
                        <i class="fa fa-check"></i>
                      </b-button>
                    </div>
                  </div>
                  <div class="non-mobile-hide col-sm-12 col-10 animated fadeIn mt-lg-3 mt-sm-1 mt-3">
                    <label v-if="!loadingBalances && localsAndLotSelected" class="mt-2 w-100">
                      Quantidade Atual: {{ selectedLocationAndSelectedLotQuantity }}
                    </label>
                  </div>
                  <div class="text-left mobile-hide col-sm-12 col-10 animated fadeIn mt-lg-3 mt-sm-1 mt-3">
                    <label v-if="!loadingBalances && localsAndLotSelected" class="mt-2 w-100">
                      Quantidade Atual: {{selectedLocationAndSelectedLotQuantity}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </b-container>
        </b-modal>

        <prepare-suggestions-modal
          ref="selectItemPrepareSuggestionsModal"
          :title="'Sugestões de Movimentos - ' + modalTitle"
          id="suggestions-modal"
          :onHidden="onHidden"
          :selectItem="selectItem"
          :loadingSuggestionLotBarcode="loadingSuggestionLotBarcode"
          :selectSuggestionByBarcodeItemLotBarcode="selectSuggestionByBarcodeItemLotBarcode"
          :readSuggestionLotBarcode="readSuggestionLotBarcode"
          :loadingSuggestionLocationBarcode="loadingSuggestionLocationBarcode"
          :selectSuggestionByBarcodeLocationBarcode="selectSuggestionByBarcodeLocationBarcode"
          :readSuggestionLocationBarcode="readSuggestionLocationBarcode"
          :foundBalances="foundBalances"
          :usePreviousLocation="usePreviousLocation"
          :previousLocation="previousLocation"
          :suggestionInitialQuantity="suggestionInitialQuantity"
          :loadingSuggestions="loadingSuggestions"
          :getSuggestions="getSuggestions"
          :selectedSuggestion="selectedSuggestion"
          :suggestionsLotPrefixes="suggestionsLotPrefixes"
          :savingMovement="savingMovement"
          :editingSuggestionQuantity="editingSuggestionQuantity"
          :suggestions="selectItem.suggestions"
          :setSuggestionManualQuantity="setSuggestionManualQuantity"
          :openLotConfirmationModal="openLotConfirmationModal"
          :openLocationConfirmationModal="openLocationConfirmationModal"
          :loadingItemLotMovements="loadingSelectItemItemLotMovements"
          :itemLotMovements="selectItem.itemLotMovements"
          :loadNextItemLotMovements="loadSelectItemNextItemLotMovements"
          :isItemLotMovementsLastPage="selectItem.isItemLotMovementsLastPage"
          :onShow="onShow"
          :updateEditingSuggestionQuantity="updateEditingSuggestionQuantity"
          :updateSelectSuggestionByBarcodeItemLotBarcode="updateSelectSuggestionByBarcodeItemLotBarcode"
          :updateSelectSuggestionByBarcodeLocationBarcode="updateSelectSuggestionByBarcodeLocationBarcode"
          :updateSuggestionInitialQuantity="updateSuggestionInitialQuantity"
          :updateUsePreviousLocation="updateUsePreviousLocation"
          :updateSuggestions="updateSuggestions"
          :numberOfVolumes="manualSelectionNumberOfVolumes"
          :updateManualSelectionNumberOfVolumes="updateManualSelectionNumberOfVolumes"
          :reloadSuggestions="reloadSuggestions"
          :config="config"
          :authUser="authUser"
          :parameter="parameter"
          :order="order"
        ></prepare-suggestions-modal>

        <prepare-suggestions-modal
          ref="wholeOrderPrepareSuggestionsModal"
          title="Sugestões de Movimentos de todos os itens"
          id="whole-order-suggestions-modal"
          :onHidden="onHidden"
          :selectItem="null"
          :loadingSuggestionLotBarcode="loadingSuggestionLotBarcode"
          :selectSuggestionByBarcodeItemLotBarcode="selectSuggestionByBarcodeItemLotBarcode"
          :readSuggestionLotBarcode="readSuggestionLotBarcode"
          :loadingSuggestionLocationBarcode="loadingSuggestionLocationBarcode"
          :selectSuggestionByBarcodeLocationBarcode="selectSuggestionByBarcodeLocationBarcode"
          :readSuggestionLocationBarcode="readSuggestionLocationBarcode"
          :foundBalances="foundBalances"
          :usePreviousLocation="usePreviousLocation"
          :previousLocation="previousLocation"
          :suggestionInitialQuantity="suggestionInitialQuantity"
          :loadingSuggestions="loadingSuggestions"
          :getSuggestions="getWholeOrderSuggestions"
          :selectedSuggestion="selectedSuggestion"
          :suggestionsLotPrefixes="suggestionsLotPrefixes"
          :savingMovement="savingMovement"
          :editingSuggestionQuantity="editingSuggestionQuantity"
          :suggestions="order.suggestions"
          :setSuggestionManualQuantity="setWholeOrderSuggestionManualQuantity"
          :openLotConfirmationModal="openLotConfirmationModal"
          :openLocationConfirmationModal="openLocationConfirmationModal"
          :loadingItemLotMovements="loadingWholeOrderItemLotMovements"
          :itemLotMovements="wholeOrderItemLotMovements"
          :loadNextItemLotMovements="loadWholeOrderNextItemLotMovements"
          :isItemLotMovementsLastPage="wholeOrderIsItemLotMovementsLastPage"
          :onShow="onShow"
          :updateEditingSuggestionQuantity="updateEditingSuggestionQuantity"
          :updateSelectSuggestionByBarcodeItemLotBarcode="updateSelectSuggestionByBarcodeItemLotBarcode"
          :updateSelectSuggestionByBarcodeLocationBarcode="updateSelectSuggestionByBarcodeLocationBarcode"
          :updateSuggestionInitialQuantity="updateSuggestionInitialQuantity"
          :updateUsePreviousLocation="updateUsePreviousLocation"
          :updateSuggestions="updateSuggestions"
          :numberOfVolumes="manualSelectionNumberOfVolumes"
          :updateManualSelectionNumberOfVolumes="updateManualSelectionNumberOfVolumes"
          :reloadSuggestions="reloadSuggestions"
          :config="config"
          :authUser="authUser"
          :parameter="parameter"
          :order="order"
        ></prepare-suggestions-modal>

        <b-modal
          size="xl"
          centered
          lazy
          :title="'Finalizar pedido ' + order.number"
          header-bg-variant="info"
          footer-border-variant="info"
          v-model="orderFinishModalShow"
          :hide-footer="true"
          id="order-finish-modal"
        >
          <b-container>
            <div v-if="order.orderItems" class="mb-3 mx-n3">
              <div v-for="(orderItem, index) in order.orderItems" class="row py-3 border mx-0" :key="index"
                :class="{ 'bg-light': index % 2 != 0 }">
                <div class="col-xl-9 col-lg-8 col-12">
                  <span  class="font-weight-bold">Item: </span>
                  {{orderItem.item ? getItemReduzidoFromRef(orderItem.item.ref) + ' - ' + orderItem.item.description : '' }}
                </div>
                <div class="col-xl-3 col-lg-4 col-12 mt-lg-0 mt-1">
                  <div class="row">
                    <div class="col">
                      <span class="font-weight-bold">Total: </span>{{orderItem.quantity || 0}}
                    </div>
                    <div class="col">
                      <span class="font-weight-bold">Separado: </span>{{orderItem.alocatedQuantity || 0}}
                    </div>
                    <div class="col" :class="{ 'text-danger': (orderItem.alocatedQuantity || 0) < (orderItem.quantity || 0) }">
                      <span class="font-weight-bold">Pendente: </span>
                      {{(orderItem.quantity || 0) - (orderItem.alocatedQuantity || 0)}}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <p class="mx-n3">
              <span v-if="atLeastOneOrderItemUnfinished" class="font-weight-bold text-warning">
                AVISO: Existem itens com quantidade pendente.
              </span><br>
              Você tem certeza de que deseja finalizar o pedido?
            </p>
          </b-container>

          <div class="row justify-content-end">
            <div class="col-auto pr-0">
              <b-button variant="secondary" @click="orderFinishModalShow = false">Não</b-button>
            </div>
            <div class="col-auto">
              <b-button variant="warning" @click="finishOrder" :disabled="savingOrder">Sim</b-button>
            </div>
          </div>
        </b-modal>
      </b-row>

      <b-modal
        size="md"
        centered
        lazy
        :title="modalTitle + ' - Selecione o Endereço de Destino'"
        header-bg-variant="info"
        footer-border-variant="info"
        v-model="destinationModalShow"
        :hide-footer="true"
        id="destination-movements-modal"
      >
        <b-container>
          <div class="row align-items-center">
            <div class="col-10">
              <model-list-select
                v-if="this.selectItem.destinationLocals"
                :list="this.selectItem.destinationLocals"
                option-value="id"
                option-text="name"
                v-model="destinationLocal"
                placeholder="Selecione o Endereço de Destino"
                @searchchange="searchDestinationAllocation"
                :filterPredicate="alwaysTrueFilterPredicate"
                :isDisabled="Boolean(order && order.destinationLocation)"
              />
            </div>
            <div v-if="loadingDestinationAllocations" class="col-2">
              <loading-animation />
            </div>
            <div v-else class="col-2">
              <b-button
                variant="secondary"
                @click.prevent="openDestinationLocationBarcodeInput"
                :disabled="Boolean(order && order.destinationLocation)"
              >
                <i class="fa fa-barcode"></i>
              </b-button>
            </div>

            <div v-if="loadingDestinationBalances || savingMovement" class="col-12">
              <loading-animation />
            </div>
            <div v-if="!loadingDestinationBalances && !savingMovement && destinationLocalSelected" class="col-12 animated fadeIn mt-3">
              <label class="mb-0">Quantidade Atual: {{selectedDestinationLocationAndSelectedLotQuantity}}</label>
            </div>
            <div class="col-10" :class="{ 'mt-3': !destinationLocalSelected, 'mt-0': destinationLocalSelected }">
              <label class="mb-0">
                Quantidade a ser inserida: {{selectedSuggestion ? suggestionInitialQuantity : quantity}}
              </label>
            </div>
            <div v-if="!loadingDestinationBalances && !savingMovement && destinationLocalSelected" class="col-2 mt-0">
              <b-button variant="primary" @click.prevent="addItem">
                <i class="fa fa-check"></i>
              </b-button>
            </div>
          </div>
        </b-container>
      </b-modal>

      <barcode-input-modal
        id="location-barcode-input-modal"
        :attempts="locationBarcodeAttempts"
        :loading="loadingLocationBarcode"
        :ok="readLocationBarcode"
        :config="config"
        :authUser="authUser"
        :parameter="parameter"
      ></barcode-input-modal>
      <barcode-input-modal
        id="lot-barcode-input-modal"
        :attempts="lotBarcodeAttempts"
        :loading="loadingLotBarcode"
        :ok="readLotBarcode"
        :config="config"
        :authUser="authUser"
        :parameter="parameter"
      ></barcode-input-modal>
      <barcode-input-modal
        id="lot-barcode-confirmation-modal"
        :title="'Confirme o código de barras do ' + (isBeepItemAutomaticLot ? 'Item' : 'Lote')"
        :withQuantity="selectItem && selectItem.item && selectItem.item.automaticItemLot && isBeepItemFromFoundBalances"
        :initialQuantity="suggestionInitialQuantity"
        :isEntrance="false"
        :attempts="lotConfirmationBarcodeAttempts"
        :ok="confirmLotBarcode"
        :config="config"
        :authUser="authUser"
        :parameter="parameter"
      ></barcode-input-modal>
      <barcode-input-modal
        id="location-barcode-confirmation-modal"
        title="Confirme o código de barras do Endereço"
        :withQuantity="true"
        :initialQuantity="suggestionInitialQuantity"
        :isEntrance="false"
        :attempts="locationConfirmationBarcodeAttempts"
        :ok="confirmLocationBarcode"
        :config="config"
        :authUser="authUser"
        :parameter="parameter"
      ></barcode-input-modal>
      <barcode-input-modal
        id="destination-location-barcode-input-modal"
        :attempts="destinationLocationBarcodeAttempts"
        :loading="loadingDestinationLocationBarcode"
        :ok="readDestinationLocationBarcode"
        :config="config"
        :authUser="authUser"
        :parameter="parameter"
      ></barcode-input-modal>
      <order-item-form-modal
        :order="order"
        :saved="orderItemSaved"
        ref="orderItemFormModal"
      ></order-item-form-modal>
      <confirmation-modal
        id="order-item-delete-confirmation-modal"
        msg="Tem certeza de que deseja remover esse item?"
        :ok="deleteOrderItem"
      ></confirmation-modal>

      <order-attendance-fab
        ref="orderAttendanceFab"
        :orderId="$route.params.id"
        type="SEPA"
      ></order-attendance-fab>
      <order-attendance-warning
        v-if="order && order.id"
        :orderId="order.id"
      ></order-attendance-warning>
    </div>
  </div>
</template>

<script>
  import { order as service } from '@/service'
  import { TimelineMax  } from 'greensock'
  import { ModelListSelect } from 'vue-search-select'
  import { httpClient } from '@/service'
  import { LoadingAnimation } from '@/components/loaders'
  import shared from '@/shared/shared'
  import orderUtils from '@/shared/order-utils'
  import BarcodeInputModal from '@/components/common/barcode-input-modal';
  import OrderItemFormModal from '@/components/forms/order-item-form-modal';
  import ConfirmationModal from '@/components/common/confirmation-modal'
  import OrderAttendanceFAB from '@/components/common/order-attendance-fab';
  import CompositeItemLotQuantity from '@/components/common/composite-item-lot-quantity';
  import PrepareSugggestionsModal from '@/components/prepare-suggestions-modal';
  import OrderAttendanceWarning from '@/components/common/order-attendance-warning';

  export default {
    name: 'prepare-start',
    components: {
      ModelListSelect,
      LoadingAnimation,
      BarcodeInputModal,
      OrderItemFormModal,
      ConfirmationModal,
      'order-attendance-fab': OrderAttendanceFAB,
      CompositeItemLotQuantity,
      'prepare-suggestions-modal': PrepareSugggestionsModal,
      OrderAttendanceWarning
    },

    data () {
      return {
        loadingAllocations: false,
        loadingLots: false,
        tl: new TimelineMax(),
        modalShow: false,
        selectedLocal: {},
        selectedLot: {},
        select: false,
        selectItem: {},
        order: {},
        quantity: null,
        manualSelectionNumberOfVolumes: null,
        isComplete: false,
        fields: [
          { key: 'name', label:'Item'},
        ],
        selectedLocationAndSelectedLotQuantity: null,
        suggestionFields: [
          { key: 'location.name', label: 'Endereço' },
          { key: 'itemLot.lotNumber', label: 'Número do Lote' },
          { key: 'formattedDate', label: 'Data do Lote' },
          { key: 'currentQuantity', label: 'Quantidade Atual' },
          { key: 'automatic', label: 'Automático' },
          { key: 'quantity', label: 'Quantidade' },
          { key: 'actions', label: 'Ações' },
        ],
        savingMovement: false,
        savingOrder: false,
        loadingBalances: false,
        loadingSuggestions: false,
        locationSearchTimeout: null,
        lotSearchTimeout: null,
        loadingLocationBarcode: false,
        locationBarcodeAttempts: 0,
        loadingLotBarcode: false,
        lotBarcodeAttempts: 0,
        lotConfirmationBarcodeAttempts: 0,
        locationConfirmationBarcodeAttempts: 0,
        selectedSuggestion: {},
        loadingOrder: false,
        editingSuggestionQuantity: false,
        loadingSuggestionLotBarcode: false,
        loadingSuggestionLocationBarcode: false,
        orderFinishModalShow: false,
        atLeastOneOrderItemUnfinished: false,
        loadingSelectItemItemLotMovements: false,
        wholeOrderIsItemLotMovementsLastPage: false,
        loadingWholeOrderItemLotMovements: false,
        wholeOrderItemLotMovements: [],
        foundBalances: [],
        config: null,
        suggestionsLotPrefixes: [],
        selectSuggestionByBarcodeItemLotBarcode: null,
        selectSuggestionByBarcodeLocationBarcode: null,
        deleteIndex: null,
        suggestionInitialQuantity: null,
        isReallocation: false,
        destinationModalShow: false,
        destinationLocal: {},
        destinationLocationSearchTimeout: null,
        loadingDestinationAllocations: false,
        selectedDestinationLocationAndSelectedLotQuantity: null,
        loadingDestinationBalances: false,
        loadingDestinationLocationBarcode: false,
        destinationLocationBarcodeAttempts: 0,
        usePreviousLocation: false,
        previousLocation: null,
        isBeepItemAutomaticLot: false,
        isBeepItemFromFoundBalances: false,
        parameter: {},
        allocationSortFields: [],
        locationGroupers: [],
        disableQuantityAutoChange: false
      }
    },

    mounted() {
      this.isReallocation = this.$route.path.includes('item-reallocation');
      this.config = shared.getConfig();
      this.authUser = shared.getAuthUser();
      this.parameter = shared.getParameterFromAuthUser(this.authUser);
      this.findOrder();
      this.findAllocationSortFields();
      this.findLocationGroupers();
    },

    beforeRouteLeave (to, from, next) {
      const options = {
        title: 'Você está prestes a parar sua tarefa de separação!',
        size: 'sm',
        okLabel: 'Sim',
        cancelLabel: 'Não',
      }

      if (!this.isComplete) {
        this.$dialogs.confirm(
          'Tem certeza de que deseja sair?',
          options
        ).then(res => {
          if (res.ok) {
            this.stopPrepare()
          }
          next(res.ok)
        })
      } else {
        next(true)
      }
    },

    computed: {
      modalTitle: function() {
        return `Item: ${this.selectItemLabel}  -- Pendente:  ${this.subtract(this.selectItem.quantity, this.selectItem.alocatedQuantity)}`
      },

      selectItemLabel() {
        return this.selectItem && this.selectItem.item ? this.getItemReduzidoFromRef(this.selectItem.item.ref) + ' - ' + this.selectItem.item.description : '';
      },

      localsAndLotSelected() {
        if (!this.selectedLocal || (!this.isSelectItemItemLotAutomatic && !this.selectedLot)) {
          return false;
        }

        let result = this.selectedLocal.id && (this.isSelectItemItemLotAutomatic || this.selectedLot.id);

        if (result) {
          this.getLocationAndLotQuantity();
          let remainingQuantity = this.subtract(this.selectItem.quantity, this.selectItem.alocatedQuantity || 0);
          let isDefaultQuantityBehaviorRemainingQuantity = this.config
            && this.config.manualSelectionConfig
            && this.config.manualSelectionConfig.defaultQuantityBehavior === 'remaining_quantity';

          if (!this.isSelectItemItemLotAutomatic && this.selectedLot.quantityPerVolume
            && !this.destinationModalShow
            && this.selectedLot.quantityPerVolume < remainingQuantity) {

            if (isDefaultQuantityBehaviorRemainingQuantity) {
              this.manualSelectionNumberOfVolumes = Math.floor(remainingQuantity / this.selectedLot.quantityPerVolume);
            } else {
              this.manualSelectionNumberOfVolumes = 1;
            }

          } else {
            this.manualSelectionNumberOfVolumes = 0;

            if (isDefaultQuantityBehaviorRemainingQuantity && !this.disableQuantityAutoChange) {
              this.quantity = remainingQuantity;
            }
          }
        }

        return result;
      },

      destinationLocalSelected() {
        let result = this.destinationLocal && this.destinationLocal.id;

        if (result) {
          this.getDestinationLocationQuantity();
        }

        return result;
      },

      isSelectItemItemLotAutomatic() {
        return this.selectItem && this.selectItem.item && this.selectItem.item.automaticItemLot;
      }
    },

    methods: {
      stopPrepare () {
        //const { _links } = localStorage.getItem('task/currentTask');

        const formData = {
          time: new Date()
        }

        //await service.stopPrepare(_links, formData)
        this.$notify.success('Separação parada com sucesso!', 'Parada!')
      },

      animate: function (box) {
        this.tl.staggerFrom(box, 0.3, {
          scale: 0,
          opacity: 0
        }, .1)
      },

      showSuggestions(orderItem) {
        if ((orderItem.alocatedQuantity || 0) >= (orderItem.quantity || 0)) {
          this.onHidden();
          return;
        }

        this.selectItem = orderItem;
        this.$refs.selectItemPrepareSuggestionsModal.showModal();
        this.focusOnSuggestionItemLotBarcodeInput();
        this.quantity = orderItem.quantity - orderItem.alocatedQuantity;
        this.resetFoundBalances();
        this.getSuggestions();

        if (!this.selectItem.itemLotMovements || !this.selectItem.itemLotMovements.length) {
          this.loadSelectItemNextItemLotMovements();
        }
      },

      onShow(orderItem) {
        /*console.log(item)

        this.selectItem = item
        this.modalShow = true*/
        this.disableQuantityAutoChange = false;
        this.selectItem = orderItem;
        this.selectedSuggestion = null;
        this.selectedLocal = {};
        this.selectedLot = {};
        //await this.$store.dispatch('allocation/getAllAvailable', item.locals[0].warehouse)

        this.modalShow = true
        //const available = await localStorage.getItem('allocation/getFiveAvailable');
        this.searchLot(null);

        if (this.order.sourceLocation) {
          if (!this.selectItem.locals) {
            this.selectItem.locals = [];
          }

          this.selectItem.locals.unshift(this.order.sourceLocation);
          this.selectedLocal = this.order.sourceLocation;
        } else {
          this.searchAllocation(null);
        }
      },

      preAddItem() {
        this.disableQuantityAutoChange = true;

        if (this.isReallocation) {
          this.openDestinatioSelectModal();
        } else {
          this.addItem();
        }
      },

      addItem() {
        /*const { uuid } = this.selectItem
        const amount = this.quantity
        const { name } = this.selectedLocal

        await service.decrementOrderItem({ uuid, amount, name })*/

        let quantity = this.quantity;
        let location = this.selectedLocal;
        let itemLot = this.selectedLot;
        let orderItem = this.selectItem;

        if (this.selectedSuggestion && this.selectedSuggestion.id) {
          quantity = this.suggestionInitialQuantity;
          location = this.selectedSuggestion.location;
          itemLot = this.selectedSuggestion.itemLot;

          /*if (this.selectItem && this.selectItem.item && this.selectItem.item.automaticItemLot) {
            quantity = this.selectedSuggestion.quantity;
          }*/

          if (this.selectedSuggestion.orderItem) {
            orderItem = this.selectedSuggestion.orderItem;
          }
        }

        quantity = Number(quantity);

        if (!quantity || isNaN(quantity)) {
          this.$notify.textError('Por favor informe a quantidade do movimento');
          return;
        }

        let body = {
          quantity: quantity,
          orderItem: orderItem,
          itemLot: itemLot,
          location: location,
          companyGroup: this.order.companyGroup
        };

        if (this.isReallocation) {
          body.destinationLocation = this.destinationLocal;
        }

        this.previousLocation = body.location;

        this.savingMovement = true;
        httpClient.post(`${process.env.VUE_APP_API_URL}item-lot-movements/`, body).then(data => {
          this.savingMovement = false;
          this.$notify.success('Movimento salvo com sucesso');
          data.data.data.formattedDate = shared.formatDate(data.data.data.createdAt);
          let selectedSuggestionCopy = null;
          let newItemLotMovements = orderUtils.getItemLotMovementsFromMovementResponse(data);

          if (this.selectedSuggestion && Object.keys(this.selectedSuggestion).length) {
            this.selectedSuggestion.exits += data.data.data.quantity;
            this.selectedSuggestion.currentQuantity -= data.data.data.quantity;
            this.quantity -= data.data.data.quantity;
            this.selectedSuggestion.quantity -= data.data.data.quantity;

            if (this.selectedSuggestion.quantity < 0) {
              this.selectedSuggestion.quantity = 0;
            }

            newItemLotMovements.forEach(nilm => {
              nilm.doesNotBelongToSuggestions = this.selectedSuggestion.doesNotBelongToSuggestions;
            });

            selectedSuggestionCopy = JSON.parse(JSON.stringify(this.selectedSuggestion));

            if (selectedSuggestionCopy.orderItem) {
              selectedSuggestionCopy.orderItem.suggestions = [];
              selectedSuggestionCopy.orderItem.balancesAvailable = [];
              selectedSuggestionCopy.orderItem.itemLotMovements = [];
            }
          }

          if (this.selectedSuggestion && this.selectedSuggestion.orderItem) {
            if (this.order && this.order.balancesAvailable && this.order.balancesAvailable.length) {
              let foundBalanceIndex = this.order.balancesAvailable.findIndex(b => b.id === this.selectedSuggestion.id);

              if (foundBalanceIndex > -1) {
                if (this.selectedSuggestion.currentQuantity > 0) {
                  this.order.balancesAvailable[foundBalanceIndex] = selectedSuggestionCopy;
                } else {
                  this.order.balancesAvailable.splice(foundBalanceIndex, 1);
                }
              }

              foundBalanceIndex = this.order.suggestions.findIndex(b => b.id === this.selectedSuggestion.id);

              if (foundBalanceIndex > -1) {
                if (this.selectedSuggestion.currentQuantity > 0) {
                  this.order.suggestions[foundBalanceIndex] = selectedSuggestionCopy;
                } else {
                  this.order.suggestions.splice(foundBalanceIndex, 1);
                }
              }
            }

            if (!this.wholeOrderItemLotMovements) {
              this.wholeOrderItemLotMovements = [];
            }

            newItemLotMovements.forEach(nilm => {
              this.wholeOrderItemLotMovements.unshift(nilm);
            });
          } else {
            let locals = this.selectItem.locals;
            let lotsAvailable = this.selectItem.lotsAvailable;
            let suggestions = this.selectItem.suggestions;
            let balancesAvailable = this.selectItem.balancesAvailable;
            let itemLotMovements = this.selectItem.itemLotMovements;
            let isItemLotMovementsLastPage = this.selectItem.isItemLotMovementsLastPage;

            this.selectItem = data.data.data.orderItem;
            this.selectItem.locals = locals;
            this.selectItem.lotsAvailable = lotsAvailable;
            this.selectItem.suggestions = suggestions;
            this.selectItem.balancesAvailable = balancesAvailable;
            this.selectItem.itemLotMovements = itemLotMovements;
            this.selectItem.isItemLotMovementsLastPage = isItemLotMovementsLastPage;

            if (!this.selectItem.itemLotMovements) {
              this.selectItem.itemLotMovements = [];
            }

            newItemLotMovements.forEach(nilm => {
              this.selectItem.itemLotMovements.unshift(nilm);
            });

            if (this.selectedSuggestion && Object.keys(this.selectedSuggestion).length) {
              if (this.selectItem && this.selectItem.balancesAvailable && this.selectItem.balancesAvailable.length) {
                let foundBalanceIndex = this.selectItem.balancesAvailable.findIndex(b => b.id === this.selectedSuggestion.id);

                if (foundBalanceIndex > -1) {
                  if (this.selectedSuggestion.currentQuantity > 0) {
                    this.selectItem.balancesAvailable[foundBalanceIndex] = selectedSuggestionCopy;
                  } else {
                    this.selectItem.balancesAvailable.splice(foundBalanceIndex, 1);
                  }
                }

                foundBalanceIndex = this.selectItem.suggestions.findIndex(b => b.id === this.selectedSuggestion.id);

                if (foundBalanceIndex > -1) {
                  if (this.selectedSuggestion.currentQuantity > 0) {
                    this.selectItem.suggestions[foundBalanceIndex] = selectedSuggestionCopy;
                  } else {
                    this.selectItem.suggestions.splice(foundBalanceIndex, 1);
                  }
                }
              }
            } else {
              this.quantity = this.selectItem.quantity - this.selectItem.alocatedQuantity;
              this.selectedLocal = this.order.sourceLocation || data.data.data.location;
              this.destinationLocal = this.order.destinationLocation || data.data.data.location || {};

              this.getLocationAndLotQuantity();
            }
          }

          for (let i = 0; i < this.order.orderItems.length; i++) {
            if (this.order.orderItems[i].id === data.data.data.orderItem.id) {
              this.order.orderItems[i] = data.data.data.orderItem;
              break;
            }
          }

          this.destinationModalShow = false;

          if (!this.selectedSuggestion || (this.selectedSuggestion && !this.selectedSuggestion.orderItem)) {
            if (this.selectItem.alocatedQuantity >= this.selectItem.quantity) {
              this.$bvModal.hide('movements-modal');
              this.$bvModal.hide('suggestions-modal');
              this.$bvModal.hide('whole-order-suggestions-modal');
            } else if (this.selectedSuggestion && this.foundBalances && this.foundBalances.length) {
              if (this.selectedSuggestion.doesNotBelongToSuggestions) {
                this.reloadSuggestions();
              }

              this.focusOnSuggestionItemLotBarcodeInput();
            }
          } else if (this.selectedSuggestion && this.selectedSuggestion.orderItem) {
            let allOrderItemsFinished = true;

            for(let i = 0; i < this.order.orderItems.length; i++) {
              let orderItem = this.order.orderItems[i];

              if (orderItem.alocatedQuantity < orderItem.quantity) {
                allOrderItemsFinished = false;
                break;
              }
            }

            if (allOrderItemsFinished) {
              this.$bvModal.hide('whole-order-suggestions-modal');
            } else if (
              (this.foundBalances && this.foundBalances.length)
              || (data.data.data.orderItem && data.data.data.orderItem.alocatedQuantity >= data.data.data.orderItem.quantity)
            ) {
              this.focusOnSuggestionItemLotBarcodeInput();

              if (this.selectedSuggestion.doesNotBelongToSuggestions) {
                this.reloadWholeOrderSuggestions(this.selectedSuggestion);
              }
            }
          }

          this.resetFoundBalances();
        }).catch(error => {
          this.savingMovement = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao salvar a movimentação')
          }
        });
      },

      onHidden (event) {
        if (event) {
          event.preventDefault();
        }

        this.quantity = this.selectItem.quantity - this.selectItem.alocatedQuantity;
        orderUtils.sortOrderItems(this.order);

        if (this.shouldFinishOrderAutomatically()) {
          const result = this.order.orderItems.filter(orderItem => orderItem.alocatedQuantity < orderItem.quantity);
          if (result.length === 0) {
            this.finishOrder();
          }
        }
      },

      finishOrder() {
        this.savingOrder = true;
        httpClient.patch(`${process.env.VUE_APP_API_URL}orders/${this.order.id}`, {
          movementStatus: 'O',
          allocationEndDate: new Date().getTime(),
        }).then(data => {
          this.savingOrder = false;
          this.isComplete = true

          //const { _links } = localStorage.getItem('task/currentTask');

          const formData = {
            time: new Date()
          }

          //await service.completeOrder(_links, formData)
          setTimeout(this.redirect, 3000);
          this.orderFinishModalShow = false;
          //this.$root.$emit('reloadOrdersCounts');
        }).catch(error => {
          this.savingOrder = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao finalizar o pedido. Por favor tente novamente')
          }
        });
      },

      onSecondLayerHidden(event) {
        this.quantity = this.selectItem.quantity - this.selectItem.alocatedQuantity;
        this.selectedLocal = this.order.sourceLocation || {};
        this.selectedLot = {};
        this.getSuggestions();
      },

      redirect () {
        window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
      },

      getLocationAndLotQuantity() {
        if (!this.selectItem || !this.selectedLocal) {
          this.selectedLocationAndSelectedLotQuantity = 0;
          return;
        }

        let url = `${process.env.VUE_APP_API_URL}balances/sum/${this.selectItem.item.id}/${this.selectedLocal.id}`;

        if (!this.isSelectItemItemLotAutomatic && this.selectedLot && this.selectedLot.id) {
          url += `?itemLotId=${this.selectedLot.id}`;
        }

        this.loadingBalances = true;
        httpClient.get(url)
        .then(({ data }) => {
          this.loadingBalances = false;
          this.selectedLocationAndSelectedLotQuantity = data.data || 0;
        })
        .catch((error) => {
          this.loadingBalances = false;
          if (error.message) {
            this.$notify.error(error)
          }
        });
      },

      searchAllocation (searchText) {
        if (!this.loadingAllocations && (
          (searchText && searchText.length) || !this.selectedLocal || Object.keys(this.selectedLocal).length == 0
        )) {
          if (this.locationSearchTimeout) {
            clearTimeout(this.locationSearchTimeout);
          }

          this.locationSearchTimeout = setTimeout(() => {
            this.loadingAllocations = true

            let conditions = [{
                logicalOperator: 'OR',
                conditions: [
                  {
                    joinType: 'LEFT',
                    field: 'item.id',
                    value: this.selectItem.item.id
                  },
                  {
                    field: 'type',
                    value: 'U'
                  }
                ]
            }];

            if (this.order && this.order.stockLocale) {
              conditions.push({
                field: 'stockLocale.id',
                value: this.order.stockLocale.id
              });
            }

            if (searchText && searchText.length) {
              conditions.push({
                field: 'name',
                conditionalOperator: 'LIKE_START',
                value: searchText
              });
            }

            httpClient
            .post(`${process.env.VUE_APP_API_URL}locations/condition?page=${0}&size=${10}`, {
              conditions: conditions
            })
            .then(({ data }) => {
              this.updateSelectedItemLocals(data.data.content);
              this.loadingAllocations = false
            })
            .catch((error) => {
              if (error.message) {
                this.$notify.error(error)
              }
              this.selectItem.locals = [];
              this.loadingAllocations = false
            });
          }, 500);
        }
      },

      searchLot (searchText) {
        if (!this.loadingLots && (
          (searchText && searchText.length) || !this.selectedLot || Object.keys(this.selectedLot).length == 0
        )) {
          if (this.lotSearchTimeout) {
            clearTimeout(this.lotSearchTimeout);
          }

          this.lotSearchTimeout = setTimeout(() => {
            this.loadingLots = true

            let conditions = [{
              field: 'item.id',
              conditionalOperator: 'EQUALS',
              value: this.selectItem.item.id
            }];

            if (searchText && searchText.length) {
              conditions.push({
                field: 'lotNumber',
                conditionalOperator: 'LIKE_START',
                value: searchText
              });
            }

            if (this.order && this.order.stockLocale) {
              conditions.push({
                field: 'balances.location.stockLocale.id',
                value: this.order.stockLocale.id
              });
            }

            httpClient
            .post(`${process.env.VUE_APP_API_URL}item-lots/condition?page=${0}&size=${10}&sort=createdAt,asc`, {
              conditions: conditions
            })
            .then(({ data }) => {
              this.selectItem.lotsAvailable = data.data.content;
              let selectItem = this.selectItem;
              this.selectItem = {};
              this.selectItem = selectItem;

              if (data.data.content.length) {
                this.selectedLot = data.data.content[0];
              }

              this.loadingLots = false
            })
            .catch((error) => {
              if (error.message) {
                this.$notify.error(error)
              }
              this.selectItem.lotsAvailable = [];
              this.loadingLots = false
            });
          }, 500);
        }
      },

      getSuggestions() {
        this.loadingSuggestions = true;
        httpClient
        .post(`${process.env.VUE_APP_API_URL}balances/order-item-exit-suggestions/${this.order.stockLocale.id}`, {
          orderItem: this.selectItem,
          previousSuggestions: this.selectItem.suggestions,
          locationId: this.order.sourceLocation ? this.order.sourceLocation.id : null
        }).then(({ data }) => {
          this.loadingSuggestions = false;
          //data.data = shared.sortByItemLotLotNumberAndLocationName(data.data);
          this.selectItem.balancesAvailable = data.data;
          this.selectItem.suggestions = data.data;

          if (this.selectItem.suggestions.length) {
            let selectItem = this.selectItem;
            this.selectItem = {};
            this.selectItem = selectItem;
          } else {
            this.$notify.warn('Nenhuma sugestão encontrada para este item');
          }
        })
        .catch((error) => {
          this.loadingSuggestions = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao buscar sugestões');
          }

          this.selectItem.balancesAvailable = [];
          this.selectItem.suggestions = [];
        });
      },

      setSuggestionManualQuantity(index) {
        this.editingSuggestionQuantity = false;
        this.selectItem.suggestions[index].automatic = false;
        this.reloadSuggestions();
      },

      reloadSuggestions() {
        //this.selectItem.balancesAvailable = shared.sortByItemLotDate(this.selectItem.balancesAvailable);

        let suggestions = shared.getSuggestionFromBalances(
          this.selectItem.balancesAvailable,
          (this.selectItem.quantity || 0) - (this.selectItem.alocatedQuantity),
          this.selectItem.suggestions
        );
        //suggestions = shared.sortByItemLotLotNumberAndLocationName(suggestions);

        let suggestionsSum = suggestions.reduce((acc, s) => {
          return acc + Number(s.quantity);
        }, 0);
        if (suggestionsSum >= this.quantity) {
          this.selectItem.suggestions = suggestions;
          let selectItem = this.selectItem;
          this.selectItem = {};
          this.selectItem = selectItem;
        } else {
          this.getSuggestions();
        }
      },

      setOrderStartDateAndStatus() {
        let body = {};
        let noQuantityAlocated = this.noQuantityAlocated();

        if (!this.order.allocationStartDate || noQuantityAlocated) {
          this.order.allocationStartDate = new Date();
          body.allocationStartDate = this.order.allocationStartDate;
        }

        if (noQuantityAlocated) {
          let startStatus = null;

          if (this.order.type === 'P' && this.parameter) {
            if (this.parameter.separationStartedStatus) {
              startStatus = this.parameter.separationStartedStatus;
            }
          }

          if (startStatus && this.order.status !== startStatus) {
            this.order.status = startStatus;
            body.status = this.order.status;
          }
        }

        if (Object.keys(body).length) {
          this.savingOrder = true;
          httpClient.patch(`${process.env.VUE_APP_API_URL}orders/${this.order.id}`, body)
          .then(data => {
            this.savingOrder = false;
          }).catch(error => {
            this.savingOrder = false;
            if (error.message) {
              this.$notify.error(error)
            } else {
              this.$notify.textError('Houve um erro ao atribuir a data de início da separação o pedido. Por favor saia e tente novamente')
            }
          });
        }
      },

      noQuantityAlocated() {
        if (this.order && this.order.orderItems) {
          for (let i = 0; i < this.order.orderItems.length; i++) {
            if (this.order.orderItems[i].alocatedQuantity) {
              return false;
            }
          }
        }

        return true;
      },

      openLocationBarcodeInput() {
        this.$bvModal.show('location-barcode-input-modal');
      },

      readLocationBarcode(barcode) {
        if (!this.loadingLocationBarcode) {
          if (barcode && barcode.length) {
            this.loadingLocationBarcode = true;
            httpClient.get(`${process.env.VUE_APP_API_URL}locations/barcode/${barcode}`)
            .then(data => {
              this.loadingLocationBarcode = false;

              if (data.data.data) {
                if (data.data.data.item && data.data.data.item.id !== this.selectItem.item.id) {
                  this.$notify.textError('Endereço não é compatível com este item');
                } else if (this.order.stockLocale && data.data.data.stockLocale.id !== this.order.stockLocale.id) {
                  this.$notify.textError('Endereço não é compatível com este armazém');
                } else {
                  this.updateSelectedItemLocals([data.data.data]);
                  this.selectedLocal = data.data.data;
                  this.$bvModal.hide('location-barcode-input-modal');
                  this.$notify.success('Endereço encontrado')
                }
              } else {
                this.$notify.textError('Endereço não encontrado')
              }

              this.locationBarcodeAttempts++;
            }).catch(error => {
              this.loadingLocationBarcode = false;
              if (error.message) {
                this.$notify.error(error)
              } else {
                this.$notify.textError('Houve um erro ao buscar o endereço pelo código de barras. Por favor tente novamente')
              }

              this.locationBarcodeAttempts++;
            });
          } else {
            this.$notify.textError('Código inválido. Por favor insira um código de barras válido')
          }
        }

        this.locationBarcodeAttempts++;
      },

      openLotBarcodeInput() {
        this.$bvModal.show('lot-barcode-input-modal');
      },

      readLotBarcode(barcode) {
        if (!this.loadingLotBarcode) {
          if (barcode && barcode.length) {
            this.loadingLotBarcode = true;
            httpClient.get(`${process.env.VUE_APP_API_URL}item-lots/barcode/${barcode}`)
            .then(data => {
              this.loadingLotBarcode = false;

              if (data.data.data) {
                if (data.data.data.item.id !== this.selectItem.item.id) {
                  this.$notify.textError('Lote não é compatível com este item');
                } else {
                  this.selectItem.lotsAvailable = [
                    data.data.data
                  ];
                  let selectItem = this.selectItem;
                  this.selectItem = {};
                  this.selectItem = selectItem;
                  this.selectedLot = data.data.data;
                  this.$bvModal.hide('lot-barcode-input-modal');
                  this.$notify.success('Lote encontrado')
                }
              } else {
                this.$notify.textError('Lote não encontrado')
              }

              this.lotBarcodeAttempts++;
            }).catch(error => {
              this.loadingLotBarcode = false;
              if (error.message) {
                this.$notify.error(error)
              } else {
                this.$notify.textError('Houve um erro ao buscar o lote pelo código de barras. Por favor tente novamente')
              }

              this.lotBarcodeAttempts++;
            });
          } else {
            this.$notify.textError('Código inválido. Por favor insira um código de barras válido')
          }
        }

        this.lotBarcodeAttempts++;
      },

      openLotConfirmationModal(suggestion, isFromFoundBalances) {
        this.selectedSuggestion = suggestion;
        this.isBeepItemFromFoundBalances = isFromFoundBalances;

        let item;
        if (suggestion.orderItem) {
          item = suggestion.orderItem.item;
        } else if (this.selectItem && this.selectItem.item) {
          item = this.selectItem.item;
        }

        this.isBeepItemAutomaticLot = item && item.automaticItemLot;

        /*if (item && item.automaticItemLot) {
          this.openLocationConfirmationModal(suggestion);
        } else {*/
          this.setSuggestionInitialQuantityForBarcodeInputModal();
          this.$bvModal.show('lot-barcode-confirmation-modal');
        //}
      },

      confirmLotBarcode(barcode, quantity) {
        if (barcode && barcode.length) {
          if (
            (!this.isBeepItemAutomaticLot && barcode === this.selectedSuggestion.itemLot.barcode)
            || (this.isBeepItemAutomaticLot && barcode === this.selectedSuggestion.itemLot.item.gtin)
          ) {
            if (this.selectItem && this.selectItem.item && this.selectItem.item.automaticItemLot && this.isBeepItemFromFoundBalances) {
              this.suggestionInitialQuantity = quantity;
            }

            this.$notify.success((this.isBeepItemAutomaticLot ? 'Item' : 'Lote') + ' confirmado')
            this.$bvModal.hide('lot-barcode-confirmation-modal');
            this.openLocationConfirmationModal();
          } else {
            this.$notify.textError('O código de barras inserido não é o mesmo do ' + (this.isBeepItemAutomaticLot ? 'item' : 'lote') + ' selecionado');
          }
        } else {
          this.$notify.textError('Código inválido. Por favor insira um código de barras válido')
        }

        this.lotConfirmationBarcodeAttempts++;
      },

      openLocationConfirmationModal(suggestion, cameFromSelectItemFoundBalances) {
        if (suggestion) {
          this.selectedSuggestion = suggestion;
        }

        if (this.isBeepItemFromFoundBalances) {
          cameFromSelectItemFoundBalances = true;
          this.isBeepItemFromFoundBalances = false;
        }

        if (
          this.selectedSuggestion && this.selectedSuggestion.id && (
            this.order.sourceLocation ||
            (cameFromSelectItemFoundBalances && this.selectItem && this.selectItem.item && this.selectItem.item.automaticItemLot)
          )
        ) {
          this.preAddItem();
        } else {
          this.setSuggestionInitialQuantityForBarcodeInputModal();
          this.$bvModal.show('location-barcode-confirmation-modal');
        }
      },

      setSuggestionInitialQuantityForBarcodeInputModal() {
        this.suggestionInitialQuantity = 0;

        if (this.selectedSuggestion && this.selectedSuggestion.id) {
          this.suggestionInitialQuantity = this.selectedSuggestion.quantity;

          if (this.selectedSuggestion.itemLot
            && this.selectedSuggestion.itemLot.quantityPerVolume
            && this.selectedSuggestion.itemLot.quantityPerVolume < this.selectedSuggestion.quantity) {
            this.suggestionInitialQuantity = this.selectedSuggestion.itemLot.quantityPerVolume;
          }
        }
      },

      confirmLocationBarcode(barcode, quantity) {
        if (barcode && barcode.length) {
          if (barcode === this.selectedSuggestion.location.barcode) {
            this.suggestionInitialQuantity = quantity;
            this.$notify.success('Endereço confirmado')
            this.$bvModal.hide('location-barcode-confirmation-modal');
            this.preAddItem();
          } else {
            this.$notify.textError('O código de barras inserido não é o mesmo do endereço selecionado');
          }
        } else {
          this.$notify.textError('Código inválido. Por favor insira um código de barras válido')
        }

        this.locationConfirmationBarcodeAttempts++;
      },

      getItemReduzidoFromRef(ref) {
        return shared.getItemReduzidoFromRef(ref);
      },

      findOrder() {
        this.loadingOrder = true;
        httpClient.get(`${process.env.VUE_APP_API_URL}orders/${this.$route.params.id}`)
        .then(data => {
          this.loadingOrder = false;
          this.order = data.data.data;
          orderUtils.sortOrderItems(this.order);
          this.setOrderStartDateAndStatus();

          this.$store.commit('app/SET_CURRENT_PAGE', {
            title:'Executando ' + (this.isReallocation ? 'Realocação' : 'Separação') + ' no Armazém ' + (this.order && this.order.stockLocale ? this.order.stockLocale.name : null),
            size: 5
          });

          this.$nextTick(() => {
            const { box } = this.$refs
            this.animate(box);

            if (this.$refs && this.$refs.orderAttendanceFab) {
              this.$refs.orderAttendanceFab.startAutomaticAttendance();
            }
          });
        }).catch(error => {
          this.loadingOrder = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError("Houve um erro ao buscar o pedido");
          }
        });
      },

      alwaysTrueFilterPredicate() {
        return true;
      },

      add(value1, value2) {
        return shared.add(value1, value2);
      },

      subtract(value1, value2) {
        return shared.subtract(value1, value2);
      },

      multiply(value1, value2) {
        return shared.multiply(value1, value2);
      },

      divide(value1, value2) {
        return shared.divide(value1, value2);
      },

      openWholeOrderSuggestionsModal() {
        this.selectItem = {};
        this.$refs.wholeOrderPrepareSuggestionsModal.showModal();
        this.focusOnSuggestionItemLotBarcodeInput();
        this.resetFoundBalances();
        this.getWholeOrderSuggestions();

        if (!this.wholeOrderItemLotMovements || !this.wholeOrderItemLotMovements.length) {
          this.loadWholeOrderNextItemLotMovements();
        }
      },

      getWholeOrderSuggestions() {
        this.loadingSuggestions = true;
        /*let body = {
          order: this.order,
          previousSuggestions: this.order.suggestions
        };*/
        //Chamado 0000424772
        let body = {
          order: this.order/*{

            //se descomentar essa parte, descomentar também a parte dentro do .then
            //para copiar o orderItem original e não perder nenhum atributo

            stockLocale: {
              id: this.order.stockLocale ? this.order.stockLocale.id : null
            },
            orderItems: (this.order.orderItems || []).map(oi => {
              return {
                id: oi.id,
                quantity: oi.quantity,
                alocatedQuantity: oi.alocatedQuantity,
                item: {
                  id: oi.item.id,
                  automaticItemLot: oi.item.automaticItemLot
                }
              }
            })
          }*/,
          previousSuggestions: (this.order.suggestions || []).map(s => {
            return {
              id: s.id,
              groupedBalancesIds: s.groupedBalancesIds,
              automatic: s.automatic,
              location: {
                id: s.location ? s.location.id : null
              },
              quantity: s.quantity,
            }
          }),
          locationId: this.order.sourceLocation ? this.order.sourceLocation.id : null
        };

        httpClient
        .post(`${process.env.VUE_APP_API_URL}balances/order-exit-suggestions`, body).then(({ data }) => {
          this.loadingSuggestions = false;

          //O orderItem enviado ao back (e consequentemente o retornado pelo back)
          //não possui todos os campos, por conta do chamado 0000424772
          /*data.data.forEach(b => {
            if (b.orderItem && b.orderItem.id) {
              let orderItem = this.order.orderItems.find(oi => oi.id === b.orderItem.id);

              if (orderItem) {
                b.orderItem = orderItem;
              }
            }
          });*/

          data.data = shared.sortByAllocationSortFields(data.data, this.allocationSortFields, this.locationGroupers);
          this.order.balancesAvailable = data.data;
          this.order.suggestions = data.data;

          if (!this.order.suggestions.length) {
            this.$notify.warn('Nenhuma sugestão encontrada para este item');
          }
        })
        .catch((error) => {
          this.loadingSuggestions = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao buscar sugestões');
          }

          this.order.balancesAvailable = [];
          this.order.suggestions = [];
        });
      },

      setWholeOrderSuggestionManualQuantity(index) {
        this.editingSuggestionQuantity = false;
        this.order.suggestions[index].automatic = false;
        this.reloadWholeOrderSuggestions(this.order.suggestions[index]);
      },

      reloadWholeOrderSuggestions(currentSuggestion) {
        let oldBalancesAvailable = this.order.balancesAvailable;
        let orderItemSuggestions = [];
        let newOrderSuggestions = [];
        let orderItemBalancesAvailable = [];
        let newOrderBalancesAvailable = [];

        this.order.suggestions.forEach(s => {
          if (s.orderItem && s.orderItem.id === currentSuggestion.orderItem.id) {
            orderItemSuggestions.push(s);
          } else {
            newOrderSuggestions.push(s);
          }
        });

        this.order.balancesAvailable.forEach(b => {
          if (b.orderItem && b.orderItem.id === currentSuggestion.orderItem.id) {
            orderItemBalancesAvailable.push(b);
          } else {
            newOrderBalancesAvailable.push(b);
          }
        });

        this.order.suggestions = newOrderSuggestions;
        this.order.balancesAvailable = newOrderBalancesAvailable;
        orderItemBalancesAvailable = shared.sortByAllocationSortFields(orderItemBalancesAvailable, this.allocationSortFields, this.locationGroupers);

        let quantity = (currentSuggestion.orderItem.quantity || 0) - (currentSuggestion.orderItem.alocatedQuantity || 0);

        orderItemSuggestions = shared.getSuggestionFromBalances(
          orderItemBalancesAvailable,
          quantity,
          orderItemSuggestions
        );

        let suggestionsSum = orderItemSuggestions.reduce((acc, s) => {
          return acc + Number(s.quantity);
        }, 0);

        if (suggestionsSum >= quantity) {
          this.order.balancesAvailable = oldBalancesAvailable;
          this.order.suggestions.push(...orderItemSuggestions);
          this.order.suggestions = shared.sortByAllocationSortFields(this.order.suggestions, this.allocationSortFields, this.locationGroupers);
          let order = this.order;
          this.order = null;
          this.order = order;
        } else {
          this.loadingSuggestions = true;
          httpClient
          .post(`${process.env.VUE_APP_API_URL}balances/order-item-exit-suggestions/${this.order.stockLocale.id}`, {
            orderItem: currentSuggestion.orderItem,
            previousSuggestions: orderItemSuggestions,
            locationId: this.order.sourceLocation ? this.order.sourceLocation.id : null
          }).then(({ data }) => {
            this.loadingSuggestions = false;

            if (data.data.length) {
              data.data.forEach(b => {
                b.orderItem = currentSuggestion.orderItem;
              });

              this.order.balancesAvailable.push(...data.data);
              this.order.suggestions.push(...data.data);
              this.order.suggestions = shared.sortByAllocationSortFields(this.order.suggestions, this.allocationSortFields, this.locationGroupers);
            } else {
              this.$notify.warn('Nenhuma sugestão encontrada');
            }
          })
          .catch((error) => {
            this.loadingSuggestions = false;
            if (error.message) {
              this.$notify.error(error)
            } else {
              this.$notify.textError('Houve um erro ao buscar sugestões');
            }
          });
        }
      },

      resetFoundBalances() {
        this.foundBalances = [];
        this.selectedSuggestion = null;
      },

      focusOnSuggestionItemLotBarcodeInput() {
        if (this.selectItem && this.selectItem.item && this.selectItem.item.automaticItemLot) {
          this.focusOnSuggestionLocationBarcodeInput();
        } else {
          setTimeout(() => {
            this.selectSuggestionByBarcodeItemLotBarcode = null;

            if (!this.usePreviousLocation)
              this.selectSuggestionByBarcodeLocationBarcode = null;

            this.$refs.selectItemPrepareSuggestionsModal.focusOnSuggestionItemLotBarcodeInput();
            this.$refs.wholeOrderPrepareSuggestionsModal.focusOnSuggestionItemLotBarcodeInput();
          }, 2);
        }
      },

      readSuggestionLotBarcode(headerInputValues) {
        this.resetFoundBalances();
        let barcode = this.selectSuggestionByBarcodeItemLotBarcode;

        if (!this.loadingSuggestionLotBarcode) {
          if (barcode && barcode.length) {
            this.loadingSuggestionLotBarcode = true;
            httpClient.get(`${process.env.VUE_APP_API_URL}item-lots/barcode/${barcode}`)
            .then(data => {
              this.loadingSuggestionLotBarcode = false;

              if (data.data.data) {
                if (this.selectItem
                  && this.selectItem.item
                  && data.data.data.item
                  && data.data.data.item.id !== this.selectItem.item.id) {
                  this.$notify.textError('Lote não é compatível com este item');
                } else {
                  let suggestions = [];
                  if (this.selectItem && this.selectItem.id) {
                    suggestions = this.selectItem.suggestions;
                  } else {
                    suggestions = this.order.suggestions;
                  }

                  let hasItemLotItemAutomaticItemLot = (!this.selectItem || !this.selectItem.id) && data.data.data.item && data.data.data.item.automaticItemLot;
                  let foundSuggestions = [];

                  suggestions.forEach(s => {
                    if (
                      (hasItemLotItemAutomaticItemLot && s.orderItem && s.orderItem.item && s.orderItem.item.id === data.data.data.item.id)
                      || (!hasItemLotItemAutomaticItemLot && s.itemLot && s.itemLot.id === data.data.data.id)
                    ) {
                      foundSuggestions.push(s);
                    }
                  });

                  if (foundSuggestions.length) {
                    this.foundBalances = foundSuggestions;

                    if (!hasItemLotItemAutomaticItemLot) {
                      this.setSuggestionsLotPrefixes();
                    }

                    this.setSuggestionInitialQuantity(data.data.data);
                    this.$notify.success('Sugestões encontradas');

                    if (this.usePreviousLocation && this.previousLocation) {
                      if (this.processSuggestionFoundLocation(this.previousLocation, headerInputValues)) {
                        return
                      }
                    } else {
                      this.focusOnSuggestionLocationBarcodeInput();
                      return;
                    }
                  } else if (!hasItemLotItemAutomaticItemLot && this.config.itemsOutConfig.allowItemLotsOutOfSuggestions) {
                    let orderItem = null;

                    if (this.selectItem && this.selectItem.id) {
                      orderItem = this.selectItem;
                    } else {
                      for (let i = 0; i < this.order.orderItems.length; i++) {
                        let oi = this.order.orderItems[i];

                        if (oi.item && oi.item.id === data.data.data.item.id) {
                          if (!orderItem) {
                            orderItem = oi;
                          } else {
                            this.$notify.textError(
                              'Existe mais de um item no pedido igual ao item desse lote.\n'
                              + 'Por favor abra as sugestões do item que você está separando.'
                            );
                            this.focusOnSuggestionItemLotBarcodeInput();
                            return;
                          }
                        }
                      }
                    }

                    if (!orderItem) {
                      this.$notify.textError('O item desse lote não foi encontrado nos itens do pedido');
                      this.focusOnSuggestionItemLotBarcodeInput();
                      return;
                    }

                    this.searchFoundBalances(data.data.data, orderItem, headerInputValues);
                    return;
                  }

                  this.$notify.textError((hasItemLotItemAutomaticItemLot ? 'Item' : 'Lote') + ' não está entre as sugestões');
                }
              } else {
                this.$notify.textError('Lote/Item não encontrado')
              }

              this.focusOnSuggestionItemLotBarcodeInput();
            }).catch(error => {
              this.loadingSuggestionLotBarcode = false;
              if (error.message) {
                this.$notify.error(error)
              } else {
                this.$notify.textError('Houve um erro ao buscar o lote pelo código de barras. Por favor tente novamente')
              }

              this.focusOnSuggestionItemLotBarcodeInput();
            });
          } else {
            this.$notify.textError('Código inválido. Por favor insira um código de barras válido')
          }
        }
      },

      focusOnSuggestionLocationBarcodeInput() {
        setTimeout(() => {
          if (this.order.sourceLocation && (!this.selectItem || !this.selectItem.item || !this.selectItem.item.automaticItemLot)) {
            let headerInputValues = this.$refs.selectItemPrepareSuggestionsModal.getHeaderInputValues()
              || this.$refs.wholeOrderPrepareSuggestionsModal.getHeaderInputValues();
            this.processSuggestionFoundLocation(this.order.sourceLocation, headerInputValues);
          } else {
            this.selectSuggestionByBarcodeLocationBarcode = null;
            this.$refs.selectItemPrepareSuggestionsModal.focusOnSuggestionLocationBarcodeInput();
            this.$refs.wholeOrderPrepareSuggestionsModal.focusOnSuggestionLocationBarcodeInput();
          }
        }, 2);
      },

      readSuggestionLocationBarcode(headerInputValues) {
        let barcode = this.selectSuggestionByBarcodeLocationBarcode;

        if (!this.loadingSuggestionLocationBarcode) {
          if (barcode && barcode.length) {
            this.loadingSuggestionLocationBarcode = true;
            httpClient.get(`${process.env.VUE_APP_API_URL}locations/barcode/${barcode}`)
            .then(data => {
              this.loadingSuggestionLocationBarcode = false;

              let ok = false;
              if (data.data.data) {
                ok = this.processSuggestionFoundLocation(data.data.data, headerInputValues);
              } else {
                this.$notify.textError('Endereço não encontrado')
              }

              if (!ok)
                this.focusOnSuggestionLocationBarcodeInput();

            }).catch(error => {
              this.loadingSuggestionLocationBarcode = false;
              if (error.message) {
                this.$notify.error(error)
              } else {
                this.$notify.textError('Houve um erro ao buscar o endereço pelo código de barras. Por favor tente novamente')
              }

              this.focusOnSuggestionLocationBarcodeInput();
            });
          } else {
            this.$notify.textError('Código inválido. Por favor insira um código de barras válido')
          }
        }
      },

      processSuggestionFoundLocation(location, headerInputValues) {
        if (this.selectItem && this.selectItem.id && location.item && location.item.id !== this.selectItem.item.id) {
          this.$notify.textError('Endereço não é compatível com este item');
        } else if (this.order.stockLocale && location.stockLocale.id !== this.order.stockLocale.id) {
          this.$notify.textError('Endereço não é compatível com este armazém');
        } else if (this.selectItem && this.selectItem.item && this.selectItem.item.automaticItemLot) {
          this.foundBalances = this.selectItem.suggestions.filter(b => b.location && b.location.id === location.id);

          if (this.foundBalances && this.foundBalances.length) {
            this.updateSelectedItemLocals([location]);
            this.$notify.success('Endereço encontrado');

            setTimeout(() => {
              if (this.foundBalances.length === 1) {
                this.$refs.selectItemPrepareSuggestionsModal.clickOnCheck(this.foundBalances[0]);
                this.$refs.wholeOrderPrepareSuggestionsModal.clickOnCheck(this.foundBalances[0]);
              } else {
                this.$refs.selectItemPrepareSuggestionsModal.focusOnFoundBalancesQuantityField();
                this.$refs.wholeOrderPrepareSuggestionsModal.focusOnFoundBalancesQuantityField();
              }
            }, 2);

            return true;
          } else {
            this.$notify.textError('Sugestão não encontrada');
          }
        } else {
          let suggestion = this.foundBalances.find(b => b.location && b.location.id === location.id);

          if (suggestion) {
            if (headerInputValues) {
              if (headerInputValues.quantity) {
                suggestion.quantity = headerInputValues.quantity;
              }

              if (headerInputValues.numberOfVolumes) {
                suggestion.numberOfVolumes = headerInputValues.numberOfVolumes;
              }
            }

            return this.addItemFromSingleSuggestion(suggestion);
          } else {
            this.$notify.textError('Esse endereço não está entre os endereços das sugestões encontradas');
          }
        }

        return false;
      },

      updateSelectedItemLocals(locals) {
        if (this.selectItem && this.selectItem.id) {
          this.selectItem.locals = locals;
          let selectItem = this.selectItem;
          this.selectItem = {};
          this.selectItem = selectItem;
        }
      },

      addItemFromSingleSuggestion(suggestion) {
        suggestion.quantity = Number(suggestion.quantity);

        if (suggestion.quantity && suggestion.quantity <= suggestion.currentQuantity) {
          if (!this.suggestionInitialQuantity) {
            this.suggestionInitialQuantity = suggestion.quantity;
          }

          //para testar
          //NÃO USAR EM PRODUÇÃO!!!
          //this.suggestionInitialQuantity = 1;

          if (this.suggestionInitialQuantity <= suggestion.currentQuantity) {
            this.selectedSuggestion = suggestion;
            this.updateSelectedItemLocals([location]);
            this.preAddItem();
            this.$notify.success('Endereço encontrado');
            return true;
          } else {
            this.$notify.textError('A quantidade informada não está disponível nesse endereço');
          }
        } else {
          this.$notify.textError('Essa sugestão não possui estoque');
        }

        return false;
      },

      openOrderFinishModal() {
        this.atLeastOneOrderItemUnfinished = false;

        for (let i = 0; i < this.order.orderItems.length; i++) {
          let orderItem = this.order.orderItems[0];

          if ((orderItem.alocatedQuantity || 0) < (orderItem.quantity || 0)) {
            this.atLeastOneOrderItemUnfinished = true;
            break;
          }
        }

        this.orderFinishModalShow = true;
      },

      loadSelectItemNextItemLotMovements() {
        if (!this.selectItem.itemLotMovements) {
          this.selectItem.itemLotMovements = [];
        }

        let size = 5;
        let page = Math.floor(this.selectItem.itemLotMovements.length / size);

        this.loadingSelectItemItemLotMovements = true;
        httpClient
        .post(`${process.env.VUE_APP_API_URL}item-lot-movements/condition?page=${page}&size=${size}&sort=createdAt,desc`, {
          conditions: [{
            field: 'orderItem.id',
            value: this.selectItem.id
          }]
        })
        .then(({ data }) => {
          this.loadingSelectItemItemLotMovements = false;
          this.selectItem.isItemLotMovementsLastPage = data.data.last;

          if (data.data.content) {
            data.data.content.forEach(ilm => {
              ilm.orderItem = null;
              ilm.formattedDate = shared.formatDate(ilm.createdAt);

              let index = this.selectItem.itemLotMovements.findIndex(currentIlm => currentIlm.id === ilm.id);
              if (index > -1) {
                this.selectItem.itemLotMovements[index] = ilm;
              } else {
                this.selectItem.itemLotMovements.push(ilm);
              }
            });
          }
        })
        .catch((error) => {
          this.loadingSelectItemItemLotMovements = false;
          if (error.message) {
            this.$notify.error(error)
          }
        });
      },

      loadWholeOrderNextItemLotMovements() {
        orderUtils.loadWholeOrderNextItemLotMovements(this);
      },

      searchFoundBalances(itemLot, orderItem, headerInputValues) {
        this.loadingSuggestionLotBarcode = true;
        httpClient
        .post(`${process.env.VUE_APP_API_URL}balances/condition?page=${0}&size=${10000}`, {
          conditions: [{
            field: 'itemLot.id',
            value: itemLot.id
          }]
        })
        .then(({ data }) => {
          this.loadingSuggestionLotBarcode = false;

          if (data.data.content && data.data.content.length) {
            data.data.content.forEach(b => {
              b.doesNotBelongToSuggestions = true;
              b.formattedDate = shared.formatDate(b.itemLot.createdAt);
              b.currentQuantity = (b.entries || 0) - (b.exits || 0);

              let remainingQuantity = (orderItem.quantity || 0) - (orderItem.alocatedQuantity || 0);
              if (remainingQuantity < b.currentQuantity) {
                b.quantity = remainingQuantity;
              } else {
                b.quantity = b.currentQuantity;
              }

              if (b.itemLot.quantityPerVolume && b.quantity >= b.itemLot.quantityPerVolume)
                  b.numberOfVolumes = Math.floor(this.divide(b.quantity, b.itemLot.quantityPerVolume));
              else
                  b.numberOfVolumes = 0;

              if (!this.selectItem || !this.selectItem.id) {
                b.orderItem = orderItem;
              }
            });

            this.foundBalances = data.data.content;
            this.setSuggestionInitialQuantity(itemLot);

            let hasItemLotItemAutomaticItemLot = (!this.selectItem || !this.selectItem.id) && itemLot.item && itemLot.item.automaticItemLot;

            if (!hasItemLotItemAutomaticItemLot) {
              this.setSuggestionsLotPrefixes();
            }

            this.$notify.success('Sugestões encontradas');
            this.$notify.warn('Esse lote não pertence às sugestões');

            if (this.usePreviousLocation && this.previousLocation) {
              if (!this.processSuggestionFoundLocation(this.previousLocation, headerInputValues))
                this.focusOnSuggestionLocationBarcodeInput();
            } else {
              this.focusOnSuggestionLocationBarcodeInput();
            }
          } else {
            this.$notify.textError('Não foram encontradas sugestões desse lote')
            this.focusOnSuggestionItemLotBarcodeInput();
          }
        })
        .catch((error) => {
          this.loadingSuggestionLotBarcode = false;
          if (error.message) {
            this.$notify.error(error)
          }

          this.focusOnSuggestionItemLotBarcodeInput();
        });
      },

      setSuggestionsLotPrefixes() {
        this.suggestionsLotPrefixes = [];

        if (this.foundBalances && this.foundBalances.length) {
          if (this.selectItem && this.selectItem.suggestions && this.selectItem.suggestions.length) {
            this.foundBalances.forEach(b => {
              if (b.itemLot && b.itemLot.lotNumber && b.itemLot.lotNumber.length) {
                let dashIndex = b.itemLot.lotNumber.indexOf('-');

                if (dashIndex > 0) {
                  let prefix = b.itemLot.lotNumber.substring(0, dashIndex);

                  if (!this.suggestionsLotPrefixes.find(p => p === prefix)) {
                    this.suggestionsLotPrefixes.push(prefix);
                  }
                }
              }
            });
          }
        }
      },

      orderItemSaved(orderItem) {
        let index = this.order.orderItems.findIndex(oi => oi.id === orderItem.id);

        if (index === -1) {
          index = this.order.orderItems.length;
        }

        this.order.orderItems[index] = orderItem;
        let orderItems = this.order.orderItems;
        this.order.orderItems = [];
        this.order.orderItems = orderItems;
      },

      openOrderItemEditModal(orderItem) {
        this.$refs.orderItemFormModal.open(orderItem);
      },

      openOrderItemContextMenu($event, orderItem, index) {
        if (!this.shouldFinishOrderAutomatically()) {
          const ctxMenuData = [
            {
              title: "Editar",
              handler: this.openOrderItemEditModal.bind(this, orderItem)
            },
            {
              title: "Excluir",
              handler: this.openOrderItemDeleteModal.bind(this, index)
            }
          ];

          this.$root.$emit("contextmenu", { event, ctxMenuData });
        }
      },

      shouldFinishOrderAutomatically() {
        return this.order.system !== 'C' && (
          !this.order.transformationMS || this.order.transformationMS.system !== 'C'
        );
      },

      openOrderItemDeleteModal(index) {
        this.deleteIndex = index;
        this.$bvModal.show('order-item-delete-confirmation-modal');
      },

      deleteOrderItem() {
        this.savingOrder = true;
        httpClient.delete(`${process.env.VUE_APP_API_URL}order-items`, this.order.orderItems[this.deleteIndex].id)
        .then(({ data }) => {
          this.savingOrder = false;
          this.$notify.success('Item excluído com sucesso');
          this.order.orderItems.splice(this.deleteIndex, 1);
        })
        .catch((error) => {
          this.savingOrder = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError('Houve um erro ao excluir o item');
          }
        });
      },

      setSuggestionInitialQuantity(itemLot) {
        this.manualSelectionNumberOfVolumes = 0;

        if (!this.usePreviousLocation) {
          this.suggestionInitialQuantity = 0;

          if (this.foundBalances.length === 1) {
            this.suggestionInitialQuantity = this.foundBalances[0].quantity;
          }
        }

        if (itemLot && itemLot.quantityPerVolume && (
          !this.suggestionInitialQuantity || itemLot.quantityPerVolume <= this.suggestionInitialQuantity)) {
          this.suggestionInitialQuantity = itemLot.quantityPerVolume;
          this.manualSelectionNumberOfVolumes = 1;
        }

        if (this.selectItem && this.selectItem.id) {
          let remainingQuantity = this.subtract(this.selectItem.quantity, this.selectItem.alocatedQuantity);

          if (this.suggestionInitialQuantity > remainingQuantity) {
            this.suggestionInitialQuantity = remainingQuantity;
            this.manualSelectionNumberOfVolumes = 0;
          }
        }
      },

      openDestinatioSelectModal() {
        this.destinationLocal = {};
        this.destinationModalShow = true;

        if (this.order.destinationLocation) {
          if (!this.selectItem.destinationLocals) {
            this.selectItem.destinationLocals = [];
          }

          this.selectItem.destinationLocals.push(this.order.destinationLocation);
          this.destinationLocal = this.order.destinationLocation;
        } else {
          this.searchDestinationAllocation(null);
        }
      },

      searchDestinationAllocation(searchText) {
        if (!this.loadingDestinationAllocations && (
          (searchText && searchText.length) || !this.destinationLocal || Object.keys(this.destinationLocal).length == 0
        ) && (
          (this.selectItem && this.selectItem.id) || (this.selectedSuggestion && this.selectedSuggestion.id)
        )) {
          if (this.destinationLocationSearchTimeout) {
            clearTimeout(this.destinationLocationSearchTimeout);
          }

          this.destinationLocationSearchTimeout = setTimeout(() => {
            this.loadingDestinationAllocations = true;

            let itemId;
            if (this.selectItem && this.selectItem.item) {
              itemId = this.selectItem.item.id;
            } else if (this.selectedSuggestion && this.selectedSuggestion.orderItem && this.selectedSuggestion.orderItem.item) {
              itemId = this.selectedSuggestion.orderItem.item.id;
            } else {
              return;
            }

            let locationId;
            if (this.selectedSuggestion && this.selectedSuggestion.location) {
              locationId = this.selectedSuggestion.location.id;
            } else if (this.selectedLocal && this.selectedLocal.id) {
              locationId = this.selectedLocal.id;
            } else {
              return;
            }

            let conditions = [
              {
                logicalOperator: 'OR',
                conditions: [
                  {
                    joinType: 'LEFT',
                    field: 'item.id',
                    conditionalOperator: 'EQUALS',
                    value: itemId
                  },
                  {
                    joinType: 'LEFT',
                    field: 'item.id',
                    conditionalOperator: 'IS_NULL'
                  }
                ]
              },
              {
                field: 'id',
                conditionalOperator: 'NOT_EQUALS',
                value: locationId
              }
            ];

            if (this.order && this.order.stockLocale) {
              conditions.push({
                field: 'stockLocale.id',
                value: this.order.stockLocale.id
              });
            }

            if (searchText && searchText.length) {
              conditions.push({
                field: 'name',
                conditionalOperator: 'LIKE_START',
                value: searchText
              });
            }

            httpClient
            .post(`${process.env.VUE_APP_API_URL}locations/condition?page=${0}&size=${10}`, {
              conditions: conditions
            })
            .then(({ data }) => {
              if (this.selectItem) {
                this.selectItem.destinationLocals = data.data.content;
                let selectItem = this.selectItem;
                this.selectItem = {};
                this.selectItem = selectItem;
              }

              this.loadingDestinationAllocations = false
            })
            .catch((error) => {
              if (error.message) {
                this.$notify.error(error)
              }
              this.selectItem.destinationLocals = [];
              this.loadingDestinationAllocations = false
            });
          }, 500);
        }
      },

      getDestinationLocationQuantity() {
        if (!this.selectItem || !this.destinationLocal) {
          this.selectedDestinationLocationAndSelectedLotQuantity = 0;
          return;
        }

        let itemId;
        let isItemLotAutomatic;
        if (this.selectItem && this.selectItem.item) {
          itemId = this.selectItem.item.id;
          isItemLotAutomatic = this.selectItem.item.automaticItemLot;
        } else if (this.selectedSuggestion && this.selectedSuggestion.orderItem && this.selectedSuggestion.orderItem.item) {
          itemId = this.selectedSuggestion.orderItem.item.id;
          isItemLotAutomatic = this.selectedSuggestion.orderItem.item.automaticItemLot;
        } else {
          return;
        }

        let itemLotId;
        if (this.selectedLot && this.selectedLot.id) {
          itemLotId = this.selectedLot.id;
        } else if (this.selectedSuggestion && this.selectedSuggestion.itemLot) {
          itemLotId = this.selectedSuggestion.itemLot.id;
        }

        let url = `${process.env.VUE_APP_API_URL}balances/sum/${itemId}/${this.destinationLocal.id}`;

        if (!isItemLotAutomatic && itemLotId) {
          url += `?itemLotId=${itemLotId}`;
        }

        this.loadingDestinationBalances = true;
        httpClient.get(url)
        .then(({ data }) => {
          this.loadingDestinationBalances = false;
          this.selectedDestinationLocationAndSelectedLotQuantity = data.data || 0;
        })
        .catch((error) => {
          this.loadingDestinationBalances = false;
          if (error.message) {
            this.$notify.error(error)
          }
        });
      },

      openDestinationLocationBarcodeInput() {
        this.$bvModal.show('destination-location-barcode-input-modal');
      },

      readDestinationLocationBarcode(barcode) {
        if (!this.loadingDestinationLocationBarcode) {
          if (barcode && barcode.length) {
            this.loadingDestinationLocationBarcode = true;
            httpClient.get(`${process.env.VUE_APP_API_URL}locations/barcode/${barcode}`)
            .then(data => {
              this.loadingDestinationLocationBarcode = false;

              if (data.data.data) {
                if (data.data.data.item && data.data.data.item.id !== this.selectItem.item.id) {
                  this.$notify.textError('Endereço não é compatível com este item');
                } else if (this.order.stockLocale && data.data.data.stockLocale.id !== this.order.stockLocale.id) {
                  this.$notify.textError('Endereço não é compatível com este armazém');
                } else if (this.selectedLocal && this.selectedLocal.id === data.data.data.id) {
                  this.$notify.textError('Endereço de destino é igual ao endereço origem. Por favor selecione outro endereço.');
                } else {
                  if (this.selectItem) {
                    this.selectItem.destinationLocals = [
                      data.data.data
                    ];
                    let selectItem = this.selectItem;
                    this.selectItem = {};
                    this.selectItem = selectItem;
                  }

                  this.destinationLocal = data.data.data || {};
                  this.$bvModal.hide('destination-location-barcode-input-modal');
                  this.$notify.success('Endereço encontrado')
                }
              } else {
                this.$notify.textError('Endereço não encontrado')
              }

              this.destinationLocationBarcodeAttempts++;
            }).catch(error => {
              this.loadingDestinationLocationBarcode = false;
              if (error.message) {
                this.$notify.error(error)
              } else {
                this.$notify.textError('Houve um erro ao buscar o endereço pelo código de barras. Por favor tente novamente')
              }

              this.destinationLocationBarcodeAttempts++;
            });
          } else {
            this.$notify.textError('Código inválido. Por favor insira um código de barras válido')
          }
        }

        this.destinationLocationBarcodeAttempts++;
      },

      updateEditingSuggestionQuantity(value) {
        this.editingSuggestionQuantity = value;
      },

      updateSelectSuggestionByBarcodeItemLotBarcode(value) {
        this.selectSuggestionByBarcodeItemLotBarcode = value;
      },

      updateSelectSuggestionByBarcodeLocationBarcode(value) {
        this.selectSuggestionByBarcodeLocationBarcode = value;
      },

      updateSuggestionInitialQuantity(value) {
        this.suggestionInitialQuantity = value;
      },

      updateUsePreviousLocation(value) {
        this.usePreviousLocation = value;
      },

      updateSuggestions(suggestions, isFoundBalances, selectItem) {
        if (isFoundBalances)
          this.foundBalances = suggestions;
        else if (selectItem)
          this.selectItem.suggestions = suggestions;
        else
          this.order.suggestions = suggestions;

        let foundBalances = this.foundBalances;
        this.foundBalances = [];

        let selectItemSuggestions = this.selectItem.suggestions;
        this.selectItem.suggestions = [];

        let orderSuggestions = this.order.suggestions;
        this.order.suggestions = [];

        this.$nextTick(() => {
          this.foundBalances = foundBalances;
          this.selectItem.suggestions = selectItemSuggestions;
          this.order.suggestions = orderSuggestions;
        });
      },

      updateManualSelectionNumberOfVolumes(value) {
        if (this.foundBalances && this.foundBalances.length && this.foundBalances[0].itemLot &&
         this.foundBalances[0].itemLot.quantityPerVolume)
          this.suggestionInitialQuantity = value * this.foundBalances[0].itemLot.quantityPerVolume;

        this.manualSelectionNumberOfVolumes = value;
      },

      findAllocationSortFields() {
        let conditions = [{ field: 'orderType', value: 'S' }];
        httpClient.post(
          `${process.env.VUE_APP_API_URL}allocation-sort-fields/condition?sort=priority,asc`,
          {
            conditions: conditions
          }
        ).then(({ data }) => {
          this.allocationSortFields = data.data.content;
        })
        .catch((error) => {
          if (error.message) {
            this.$notify.error(error)
          }
        });
      },

      findLocationGroupers() {
        httpClient.get(`${process.env.VUE_APP_API_URL}location-groupers?page=0&size=100`)
        .then(({ data }) => {
          this.locationGroupers = data.data.content;
          this.locationGroupers = shared.sortLocationGroupers(this.locationGroupers);
        })
        .catch((error) => {
          if (error.message) {
            this.$notify.error(error)
          }
          this.locationGroupers = [];
        });
      }
    },

    watch: {
      usePreviousLocation(newValue, oldValue) {
        if (newValue) {
          if (this.previousLocation && this.previousLocation.barcode && this.previousLocation.barcode.length)
            this.selectSuggestionByBarcodeLocationBarcode = this.previousLocation.barcode;
        } else {
          this.selectSuggestionByBarcodeLocationBarcode = null;
        }
      },

      manualSelectionNumberOfVolumes(newValue, oldValue) {
        if(this.selectedLot && this.selectedLot.quantityPerVolume && newValue) {
          this.$set(this, 'quantity', this.selectedLot.quantityPerVolume * newValue);
        }
      }
    },
  }
</script>

<style>
  .v-dialog:target .v-dialog-overlay,
  .v-dialog.active .v-dialog-overlay {
    background: rgba(51, 58, 64, 0.75) !important;
  }

  .margin-right {
    margin-right: .8rem;
  }

  .pointer {
    cursor: pointer;
  }
</style>
